import engineering from "../../assets/icons/Default-Icons/engineering_1.webp";
import banking from '../../assets/icons/Default-Icons/banking_2.webp'
import state_gov from '../../assets/icons/Default-Icons/state_gov_3.webp'
import teaching from '../../assets/icons/Default-Icons/teaching_4.webp'
import technology from '../../assets/icons/Default-Icons/technology_5.webp'
import railway from '../../assets/icons/Default-Icons/railway_6.webp'
import defence from '../../assets/icons/Default-Icons/defence_7.webp'
import accounts from '../../assets/icons/Default-Icons/accounts_8.webp'
import central_gov from '../../assets/icons/Default-Icons/central_gov_9.webp'
import gpsc from '../../assets/icons/Default-Icons/gpsc_10.webp'
import police from '../../assets/icons/Default-Icons/police_11.webp'
import upsc from '../../assets/icons/Default-Icons/upsc_12.webp'
import fresher from '../../assets/icons/Default-Icons/fresher_13.webp'
import sales from '../../assets/icons/Default-Icons/sales_14.webp'
import marketing from '../../assets/icons/Default-Icons/marketing_15.webp'
import finance from '../../assets/icons/Default-Icons/finance_16.webp'

export const CURRENT_AFFAIRS_ITEMS_PER_PAGE = 6;


export const getDefaultImage = (id: any) => {

    switch (+id) {
        case 1:
            return engineering
        case 2:
            return banking
        case 3:
            return state_gov
        case 4:
            return teaching
        case 5:
            return technology
        case 6:
            return railway
        case 7:
            return defence
        case 8:
            return central_gov
        case 9:
            return accounts
        case 10:
            return gpsc
        case 11:
            return police
        case 12:
            return upsc
        case 13:
            return fresher
        case 14:
            return sales
        case 15:
            return marketing
        case 16:
            return finance

        default:
            return fresher
    }
}


export const getFirstLetterCapital = (sentence: string = '') => {
    const words = (sentence || '').trim().toLowerCase().split(" ");

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i]?.length ? words[i][0].toUpperCase() + words[i].substring(1) : words[i];
    }

    return words.join(' ');
}


export const getFullURLPath = () => {
    return typeof window !== "undefined" && window.location.href
        ? window.location.href
        : ""
}


export const getSharingTextForJobs = (url: string) => {
    return (`Find more Job Openings on Freshment  and Be a Part of 50,000+ Candidates Growing Network \n\n Download App Today https://play.google.com/store/apps/details?id=com.govtjobs.naukari \n\n Be a helping hand to your friends. Share this position - ${url}`)
}

export const getSharingCurrentAffairText = (url: string) => {
    return (`Find Daily Current Affairs on Freshment  and Be a Part of 50,000+ Candidates Growing Network \n\n Download App Today https://play.google.com/store/apps/details?id=com.govtjobs.naukari \n\n Be a helping hand to your friends. Share this news today - ${url}`)
}

export const getShareMsgGovtJobpaperSyllabus = (url: string) => {
    return (`Stay Updated with Freshment on Govt Exam Syllabus and Papers and Be a Part of 50,000+ Candidates Growing Network \n\n Download App Today https://play.google.com/store/apps/details?id=com.govtjobs.naukari \n\n Be a helping hand to your friends. Share this Material today - ${url}`)
}

export const getShareMsgEbook = (url: string) => {
    return (`Stay Updated with Freshment on Getting New E-Books and Be a Part of 50,000+ Candidates Growing Network \n\n Download App Today https://play.google.com/store/apps/details?id=com.govtjobs.naukari \n\n Be a helping hand to your friends. Share this Material today - ${url}`)
}

export const getShareMsgPlacementPaper = (url: string) => {
    return (`Let's prepare for placement with latest placement papers on Freshment and Be a Part of 50,000+ Candidates Growing Network \n\n Download App Today https://play.google.com/store/apps/details?id=com.govtjobs.naukari \n\n Be a helping hand to your friends. Share this Placement Papers today - ${url}`)
}

export const getShareMsgGK = (url: string) => {
    return (`Stay Updated with Freshment on Daily GK Events and Be a Part of 50,000+ Candidates Growing Network \n\n Download App Today https://play.google.com/store/apps/details?id=com.govtjobs.naukari \n\n Be a helping hand to your friends. Share this Material today - ${url}`)
}


export const deepCopyJSON = (jsonData) => {
    return JSON.parse(JSON.stringify(jsonData))
}