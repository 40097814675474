import React, { useEffect, useState } from "react";
import {
  DATE_POSTED_FILTERS,
  QUALIFICATION_FILTERS,
} from "./../constants/constants";
import {
  AllJobs,
  EmptyState,
  JobFilter,
  MobileFilters,
  PaginationComponent,
  SearchBar,
} from "../../shared-components";
import jobDescHeaderBg from "../../assets/images/job-desc-header-bg.svg";
import filter from "../../assets/icons/filter.svg";
import { useGovtJobsList } from "../../network/APIs/jobs";
import { getGovtJobsCategories } from "../../network/APIs/categories";
import { IFiltersGroup } from "../../shared-components/interfaces/current-affairs";
import { IJobCategories } from "../../shared-components/interfaces/job-categories.interface";
import { IHandlePageChangeEvent } from "../../shared-components/interfaces/common.interface";
import govtJobsUnderline from "../../assets/icons/govt-jobs-underline.svg";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function GovernmentJob() {
  const ITEMS_PER_PAGE = 5;
  // const router = useRouter();
  // const {
  //   query: { categoryId, search, page, date_posted, qualification },
  //   pathname,
  // } = useRouter();

  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams.get("categoryId");
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const date_posted = searchParams.get("date_posted");
  const qualification = searchParams.get("qualification");

  const [query, setQuery] = useState<any>();
  const { data: filteredItems } = useGovtJobsList(query);

  const [filters, setFilters] = useState<IFiltersGroup[]>([
    DATE_POSTED_FILTERS,
    QUALIFICATION_FILTERS,
  ]);

  // Fetch current affairs, job categories
  useEffect(() => {
    getGovtJobsCategories().then((result) => {
      if (result.success) {
        const newCategory: IFiltersGroup = {
          label: "Category",
          type: "checkbox",
          value: "categoryId",
          filters: [],
        };
        result.data?.categories.forEach((category: IJobCategories) => {
          newCategory.filters.push({
            id: Number(category.id),
            lable: category.category_name,
            value: category.id,
          });
        });
        setFilters((prev) => [newCategory, ...prev]);
      } else {
        setFilters((prev) => [...prev]);
      }
    });
  }, []);

  // On category query param change
  useEffect(() => {
    const newQuery = {
      categoryId: Array.isArray(categoryId) ? categoryId.join(",") : categoryId,
      search,
      date_posted,
      qualification,
      offset:
        ((isNaN(Number(page)) || !Number(page) ? 1 : Number(page)) - 1) *
        ITEMS_PER_PAGE,
      limit: ITEMS_PER_PAGE,
    };
    setQuery(newQuery);
  }, [categoryId, page, search, date_posted, qualification]);

  // Handle filter
  const handleFilterChange = (appliedFilters: IFiltersGroup[]) => {
    const queryParams: { [key: string]: any } = {};

    appliedFilters.forEach((filter_group) => {
      const groupValues: any = [];

      filter_group.filters.forEach((filter) => {
        if (filter.isChecked) {
          groupValues.push(filter.value);
        }
      });

      if (groupValues.length) {
        queryParams[filter_group.value] =
          groupValues.length === 1 ? groupValues[0] : groupValues;
      }
    });

    searchParams.delete("categoryId");
    searchParams.delete(DATE_POSTED_FILTERS.value);
    searchParams.delete(QUALIFICATION_FILTERS.value);
    Object.keys(queryParams).forEach((key) => {
      searchParams.append(key, queryParams[key]);
    });
    searchParams.delete("page");
    setSearchParams(searchParams);
  };


  const handleSearch = (search: string) => {
    searchParams.delete("page");

    if (search && search.trim().length) {
      searchParams.set("search", search.trim());
      setSearchParams(searchParams);
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  };

  const handlePageChange = ({ page }: IHandlePageChangeEvent) => {
    searchParams.set("page", page + "");
    setSearchParams(searchParams);
  };

  return (
    <>
      <Helmet>
        <title>
          Latest Govt Job updates | Exam Info, Study Material, GK, Current
          Affairs, GPSC, UPSC, BANK EXAMS, STUDY MATERIALS, DOWNLOADS, MOCK
          TEST, RESULT and many more | Freshment
        </title>

        <meta
          name="title"
          content="Latest Government Jobs Notifications 2023 on Freshment | freshmentapp.com"
        />

        <meta
          name="description"
          content="Get All Central &amp; State Government Jobs / Sarkari Naukri recruitment Notifications, Employment News of Public Sector, Railway and Bank Jobs in India, free job alerts for freshers on freshmentapp.com"
        />

        <meta
          name="image"
          content="https://freshmentapp.com/static/media/freshmentLogo.a5200e9f.svg"
        />

        <link rel="canonical" href="https://freshmentapp.com/government-job" />
        <link
          rel="alternate"
          href="https://freshmentapp.com/government-job"
          hrefLang="en-in"
        />

        <meta name="author" content="freshment" />
        <meta property="business:contact_data:locality" content="Rajkot" />
        <meta property="business:contact_data:postal_code" content="360002" />
        <meta property="business:contact_data:country_name" content="India" />
        <meta
          property="business:contact_data:email"
          content="contact@freshmentapp.com"
        />
        <meta
          property="business:contact_data:website"
          content="https://freshmentapp.com"
        />
        <meta
          itemProp="name"
          content="Latest Government Jobs Notifications 2023 on Freshment | freshmentapp.com"
        />
        <meta property="profile:username" content="freshment" />
        <meta
          itemProp="description"
          content="Get All Central &amp; State Government Jobs / Sarkari Naukri recruitment Notifications, Employment News of Public Sector, Railway and Bank Jobs in India, free job alerts for freshers on freshmentapp.com"
        />

        <meta
          property="og:title"
          content="Latest Government Jobs Notifications 2023 on Freshment | freshmentapp.com"
        />

        <meta
          name="og:description"
          content="Get All Central &amp; State Government Jobs / Sarkari Naukri recruitment Notifications, Employment News of Public Sector, Railway and Bank Jobs in India, free job alerts for freshers on freshmentapp.com"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://freshmentapp.com" />
        <meta
          property="og:determiner"
          content="Post Jobs To Get Candidates | Hire Staff Easily In 72 Hours"
        />
        <meta
          name="twitter:title"
          content="Latest Government Jobs Notifications 2023 on Freshment | freshmentapp.com"
        />

        <meta
          name="twitter:description"
          content="Get All Central &amp; State Government Jobs / Sarkari Naukri recruitment Notifications, Employment News of Public Sector, Railway and Bank Jobs in India, free job alerts for freshers on freshmentapp.com"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="freshment" />
        <meta
          name="twitter:image"
          content="https://freshmentapp.com/static/media/about-office-1.1b443800.png"
        />
        <meta name="robots" content="index,follow" />

        <meta name="google" content="notranslate" />
        <meta http-equiv="Content-Language" content="en_US" />

        <meta
          name="keywords"
          content="government job search, govtjobs, work, employment, career, resumes, job vacancies, government jobs, ojas, GPSC, UPSC,  call letters, results, gujarat jobs, recruitment, internships, gujarat government jobs, PSU, Railway Jobs, Banking Jobs, Teaching Jobs , Recruitment in Gujarat, Gujarat careers, Gujarat employment, Gujarat job listings, Gujarat job search, Gujarat search engine, work in Gujarat"
        />
      </Helmet>
      {/* Header Starts */}
      <div
        style={{
          backgroundImage: `url(${jobDescHeaderBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#F8F8FD",
        }}
        className="px-[124px] sm:px-[40px] pt-[88px] pb-[40px]"
      >
        <h1 className="text-[48px] font-[700] leading-[79px] sm:leading-[60px] sm:text-[40px] font-DmSans">
          Find Your{" "}
          <span className="text-[#FF5F14] font-DmSans">Government Jobs</span>
        </h1>
        <img
loading="lazy"            src={govtJobsUnderline}
          alt="bg-graphic"
          className="sm:hidden mb-[20px] ml-[204px] mt-[-10px]"
        />
        <p className="text-[20px] text-[#515B6F] mb-[40px]">
          Get All Central & State Government Jobs / Sarkari Naukri recruitment
          Notifications, Employment News of Public Sector, Railway and Bank Jobs
          in India, free job alerts
        </p>
        <div>
          <SearchBar
            handleSearch={handleSearch}
            placeholder="Job title or keyword"
            subtitle="Popular : IAS Officer, Income Tax Department ,Police Head, Health Department"
          />
        </div>
      </div>
      {/* Header Ends */}

      <div className="bg-[#F8F8FD] sm:px-[40px] px-[124px] pb-[80px] flex gap-[32px]">
        {!filteredItems?.data?.length ? (
          <EmptyState className="min-h-[300px]" />
        ) : (
          <div className="grow-1 w-full">
            <div className="flex justify-between">
              <h4 className="text-[#25324B] text-[30px] font-[700] font-DmSans">
                All Jobs
              </h4>
            </div>
            <p className="text-[#7C8493] font-[500] mb-[30px]">
              Showing {filteredItems?.totalRecords} results
            </p>
            <div className="hidden sm:flex sm:justify-center mt-[10px] mb-[10px] gap-[4px]">
              <MobileFilters
                filters={filters}
                handleFilterChange={handleFilterChange}
              />
            </div>
            <div className="grid gap-[16px]">
              {filteredItems?.data?.map((job) => (
                <AllJobs {...job} key={job.id} isGovtJob={true} />
              ))}
            </div>

            <div className="mt-[34px]">
              <PaginationComponent
                totalRecords={filteredItems?.totalRecords}
                itemsPerPage={ITEMS_PER_PAGE}
                handlePageChange={handlePageChange}
                setPageTo={+page === 0 ? 1 : +page}
              ></PaginationComponent>
            </div>
          </div>
        )}
        <div className="min-w-[300px]  sm:hidden">
          <div className="flex mt-[28px] mb-[48px] gap-[4px]">
            <img loading="lazy" src={filter} alt="" width={24} />
            <p className="text-[#707070] font-[600]">Filter</p>
          </div>
          <JobFilter
            filters={filters}
            handleFilterChange={handleFilterChange}
          />
        </div>
      </div>
    </>
  );
}

export default GovernmentJob;
