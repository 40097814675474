import { SUBSCRIBE_NOW } from './../../shared/constants/routing-constants';
import { postData } from '../api-client';
import { FEED } from '../../shared/constants/routing-constants';


export function subscribeNewsLetter(jsonData: any) {
  const formData = new FormData();

  for (const key in jsonData) {
    if (Object.prototype.hasOwnProperty.call(jsonData, key)) {
      const element = jsonData[key];
      formData.append(key, element)
    }
  }

  return postData(`${FEED}/${SUBSCRIBE_NOW}`, formData);
}