import { useLayoutEffect, useState } from "react";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const getFileNameFromFullURL = (url: string): string => {
  const fileName = url.split("/").pop();
  return fileName || "New file.pdf";
};

export const showAlert = (msg: string) => {
  alert(msg);
};

export const isValidUrl = (urlString: string) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export default scrollToTop;

export const useWindowSize = (): number[] => {
  const [windowSize, setWindowSize] = useState([0, 0]);
  const updateWindowSize = () => {
    setWindowSize([window.innerWidth, window.innerHeight]);
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    updateWindowSize();
    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);
  return [windowSize[0], windowSize[1]];
};

// 1 -> st, 2->nd...
export const nth = (d: number | string) => {
  const dString = String(d);
  const last = +dString.slice(-2);
  if (last > 3 && last < 21) return "th";
  switch (last % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

export const convertDateTothFormat = (d: string) => {
  try {
    let current_datetime = new Date(d);
    let formatted_date =
      current_datetime.getDate()+nth(current_datetime.getDate()) +
      " " +
      months[current_datetime.getMonth()] +
      ", " +
      current_datetime.getFullYear();
    return formatted_date;
  } catch {
    return d
  }
};


export const convertHtMLtoText = (htmlString: string):string => {
  try {
    return htmlString.replace(/<[^>]+>/g, "");
  } catch (error) {
    return htmlString;
  }
}