import  { useState, useEffect } from "react";
import {
  DATE_POSTED_FILTERS,
  QUALIFICATION_FILTERS,
} from "./../constants/constants";
import {
  AllJobs,
  EmptyState,
  JobFilter,
  MobileFilters,
  PaginationComponent,
  SearchBar,
} from "../../shared-components";
import jobDescHeaderBg from "../../assets/images/job-desc-header-bg.svg";
import filter from "../../assets/icons/filter.svg";
import { IFiltersGroup } from "../../shared-components/interfaces/current-affairs";
import { getFreshJobsCategories } from "../../network/APIs/categories";
import { IJobCategories } from "../../shared-components/interfaces/job-categories.interface";
import { IHandlePageChangeEvent } from "../../shared-components/interfaces/common.interface";
import fresherJobsUnderline from "../../assets/icons/fresher-jobs-underline.svg";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useFreshersJobsList } from "../../network/APIs/jobs";

function FreshersJob() {
  const ITEMS_PER_PAGE = 5;

  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams.get("categoryId");
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const date_posted = searchParams.get("date_posted");
  const qualification = searchParams.get("qualification");

  const [query, setQuery] = useState<any>();
  const { data: filteredItems } = useFreshersJobsList(query);

  const [filters, setFilters] = useState<IFiltersGroup[]>([
    DATE_POSTED_FILTERS,
    QUALIFICATION_FILTERS,
  ]);

  // Fetch current affairs, job categories
  useEffect(() => {
    getFreshJobsCategories().then((result) => {
      if (result.success) {
        const newCategory: IFiltersGroup = {
          label: "Category",
          type: "checkbox",
          value: "categoryId",
          filters: [],
        };
        result.data?.categories.forEach((category: IJobCategories) => {
          newCategory.filters.push({
            id: Number(category.id),
            lable: category.category_name,
            value: category.id,
          });
        });
        setFilters((prev) => [newCategory, ...prev]);
      } else {
        setFilters((prev) => [...prev]);
      }
    });
  }, []);

  // On category query param change
  useEffect(() => {
    const newQuery = {
      categoryId: Array.isArray(categoryId) ? categoryId.join(",") : categoryId,
      search,
      date_posted,
      qualification,
      offset:
        ((isNaN(Number(page)) || !Number(page) ? 1 : Number(page)) - 1) *
        ITEMS_PER_PAGE,
      limit: ITEMS_PER_PAGE,
    };
    setQuery(newQuery);
  }, [categoryId, search, page, date_posted, qualification]);

  // Handle filter
  const handleFilterChange = (appliedFilters: IFiltersGroup[]) => {
    const queryParams: { [key: string]: any } = {};

    appliedFilters.forEach((filter_group) => {
      const groupValues: any = [];

      filter_group.filters.forEach((filter) => {
        if (filter.isChecked) {
          groupValues.push(filter.value);
        }
      });

      if (groupValues?.length) {
        queryParams[filter_group.value] =
          groupValues.length === 1 ? groupValues[0] : groupValues;
      }
    });

    searchParams.delete("categoryId");
    searchParams.delete(DATE_POSTED_FILTERS.value);
    searchParams.delete(QUALIFICATION_FILTERS.value);
    Object.keys(queryParams).forEach((key) => {
      searchParams.append(key, queryParams[key]);
    });
    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const handleSearch = (search: string) => {
    searchParams.delete("page");
    if (search && search.trim().length) {
      searchParams.set("search", search.trim());
      setSearchParams(searchParams);
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  };

  const handlePageChange = ({ page }: IHandlePageChangeEvent) => {
    searchParams.set("page", page + "");
    setSearchParams(searchParams);
  };

  return (
    <>
      <Helmet>
        <title>
          Apply for 1000+ Job Vacancies on Freshment, Search Fresher Jobs,
          Engineering, MBA Job Openings
        </title>

        <meta
          name="title"
          content="Apply for 1000+ Job Vacancies on Freshment, Search Fresher Jobs, Engineering, MBA Job Openings"
        />

        <meta
          name="description"
          content="Apply for 1000 Software, Engineering, MBA Sales, Finance, Marketing, Diploma, Commerce, Arts  Job Openings on Freshment. Get Daily Job Alerts. ✓ Work from Home ✓ Online ✓ Freshers"
        />

        <meta
          name="image"
          content="https://freshmentapp.com/static/media/freshmentLogo.a5200e9f.svg"
        />

        <link rel="canonical" href="https://freshmentapp.com/freshers-job" />
        <link
          rel="alternate"
          href="https://freshmentapp.com/freshers-job"
          hrefLang="en-in"
        />

        <meta
          itemProp="description"
          content="Apply for 1000 Software, Engineering, MBA Sales, Finance, Marketing, Diploma, Commerce, Arts  Job Openings on Freshment. Get Daily Job Alerts. ✓ Work from Home ✓ Online ✓ Freshers"
        />

        <meta
          property="og:title"
          content="Apply for 1000+ Job Vacancies on Freshment, Search Fresher Jobs, Engineering, MBA Job Openings"
        />

        <meta
          name="og:description"
          content="Apply for 1000 Software, Engineering, MBA Sales, Finance, Marketing, Diploma, Commerce, Arts  Job Openings on Freshment. Get Daily Job Alerts. ✓ Work from Home ✓ Online ✓ Freshers"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://freshmentapp.com" />
        <meta
          property="og:determiner"
          content="Post Jobs To Get Candidates | Hire Staff Easily In 72 Hours"
        />
        <meta
          name="twitter:title"
          content="Apply for 1000+ Job Vacancies on Freshment, Search Fresher Jobs, Engineering, MBA Job Openings"
        />

        <meta
          name="twitter:description"
          content="Apply for 1000 Software, Engineering, MBA Sales, Finance, Marketing, Diploma, Commerce, Arts  Job Openings on Freshment. Get Daily Job Alerts. ✓ Work from Home ✓ Online ✓ Freshers"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="freshment" />
        <meta
          name="twitter:image"
          content="https://freshmentapp.com/static/media/about-office-1.1b443800.png"
        />
        <meta http-equiv="Content-Language" content="en_US" />
        <meta
          name="keywords"
          content="job search, fresherjobs, work, employment, career, resumes, job vacancies, government jobs, careerbuilder, part time jobs, work from home jobs, recruitment, software engineer jobs, jobs in 2023, internships, Fresher Jobs, apna jobs, job portal, job search app, free job posting, apply job online, job portals in india, job portals in gujarat, local jobs, iti jobs, data entry jobs, engineering jobs, mba jobs, mtech jobs, best job search app, internships"
        />
      </Helmet>
      {/* Header Starts */}
      <div
        style={{
          backgroundImage: `url(${jobDescHeaderBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#F8F8FD",
        }}
        className="px-[124px] sm:px-[40px]  pt-[88px] pb-[40px]"
      >
        <h1 className="text-[48px] font-[700] leading-[79px] sm:leading-[60px] sm:text-[40px] font-DmSans">
          Find Your{" "}
          <span className="text-[#FF5F14] font-DmSans">Freshers Jobs</span>
        </h1>
        <img
loading="lazy"            src={fresherJobsUnderline}
          alt="bg-graphic"
          className=" sm:hidden mb-[20px] ml-[204px] mt-[-10px]"
        />
        <p className="text-[20px] text-[#515B6F] mb-[40px]">
          Apply for 1000 Software, Engineering, MBA Sales, Finance, Marketing,
          Diploma, Commerce, Arts Job Openings on Freshment. Get Daily Job
          Alerts.
        </p>
        <div>
          <SearchBar
            handleSearch={handleSearch}
            placeholder="Job title or keyword"
            subtitle="Popular : UI Designer, UX Researcher, Android, Admin"
          />
        </div>
      </div>
      {/* Header Ends */}

      <div className="bg-[#F8F8FD] sm:px-[40px]  px-[124px] pb-[80px] flex gap-[32px]">
        {!filteredItems?.data?.length ? (
          <EmptyState className="min-h-[300px]" />
        ) : (
          <div className="grow-1 w-full">
            <div className="flex justify-between">
              <h4 className="text-[#25324B] text-[30px] font-[700] font-DmSans">
                All Jobs
              </h4>
            </div>
            <p className="text-[#7C8493] font-[500] mb-[30px]">
              Showing {filteredItems?.totalRecords} results
            </p>
            <div className="hidden sm:flex mt-[10px] sm:justify-center mb-[10px] gap-[4px]">
              <MobileFilters
                filters={filters}
                handleFilterChange={handleFilterChange}
              />
            </div>
            <div className="grid gap-[16px]">
              {filteredItems?.data?.map((job) => (
                <AllJobs {...job} key={job.id} />
              ))}
            </div>

            <div className="mt-[34px]">
              <PaginationComponent
                totalRecords={filteredItems?.totalRecords}
                itemsPerPage={ITEMS_PER_PAGE}
                handlePageChange={handlePageChange}
                setPageTo={+page === 0 ? 1 : +page}
              ></PaginationComponent>
            </div>
          </div>
        )}
        <div className="min-w-[300px] sm:hidden">
          <div className="flex mt-[28px] mb-[48px] gap-[4px]">
            <img loading="lazy" src={filter} alt="Image" width={24} />
            <p className="text-[#707070] font-[600]">Filter</p>
          </div>
          <JobFilter
            filters={filters}
            handleFilterChange={handleFilterChange}
          />
        </div>
      </div>
    </>
  );
}

export default FreshersJob;
