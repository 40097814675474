import React, { useEffect, useState } from "react";
import searchIcon from "./../assets/icons/search.svg";
import freshmentLogoWhite from "./../assets/icons/freshmentLogoWhite.svg";
import facebook from "./../assets/icons/facebook.svg";
import instagram from "./../assets/icons/instagram.svg";
import linkedin from "./../assets/icons/linkedIn.svg";
import twitter from "./../assets/icons/twitter.svg";
import hamburger from "./../assets/icons/hamburger.svg";
import filter from "./../assets/icons/filter.svg";
import closeIcon from "./../assets/icons/close.svg";
import jobDescHeaderBg from "./../assets/images/job-desc-header-bg.svg";

import freshmentLogo from "./../assets/icons/freshmentLogo.svg";
import suitcase from "./../assets/icons/suitcase.svg";
import callIcon from "./../assets/icons/call.svg";
import currencyRupee from "./../assets/icons/currencyRupee.svg";
import biPeopleFill from "./../assets/icons/bi-people-fill.svg";
import share from "./../assets/icons/share.svg";
import smallBuilding from "./../assets/icons/smallBuilding.svg";
import smallLocation from "./../assets/icons/smallLocation.svg";
import smallSuitcase from "./../assets/icons/smallSuitcase.svg";
import smallWatch from "./../assets/icons/smallWatch.svg";
import clock from "./../assets/icons/clock.svg";
import color_facebook from "./../assets/icons/color_facebook.svg";
import color_twitter from "./../assets/icons/color_twitter.svg";
import color_linkedin from "./../assets/icons/color_linkedin.svg";
import { format } from "date-fns";
// import loginUser from "/assets/icons/Default Icons/login_user.jpg";
import bookDefault from "./../assets/icons/book-default.png";
import defaultCandidate from "./../assets/images/default-candidate-profile-pic.png";

// Footer blue banner imports
import blueFooterBg from "./../assets/images/Rectangle-footer-bg.svg";
import blueRightFooterBg from "./../assets/images/footer-banner-right.svg";
import blueLeftFooterBg from "./../assets/images/footer-banner-right.svg";
import blueMobileFooterBg from "./../assets/images/mobile-screen.svg";
import { IMenuItem } from "./interfaces/menu-item.interface";
import ReactPaginate from "react-paginate";
import {
  IGovtJobsList,
  IHomePageGovtJobs,
  IHomePageJobCategories,
  IJobData,
} from "./interfaces/job-categories.interface";
import {
  ICurrentAffairDetails,
  ICurrentAffairs,
  IFiltersGroup,
} from "./interfaces/current-affairs";
import { IHandlePageChangeEvent } from "./interfaces/common.interface";
// import {
//   CURRENT_AFFAIRS_APP,
//   JOB_DETAILS_APP,
// } from "../shared/constants/routing-constants";

import useDownloader from "react-use-downloader";
// import { postFeedback } from "../network/APIs/feedback";
// import { postCampusConnect } from "../network/APIs/campus";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { Grid, Popover } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import SignIn from "../pages/auth/signIn";
// import SignUp from "../pages/auth/signUp";
// import ForgotPassword from "../pages/auth/forgotPassword";
import {
  getDefaultImage,
  getFirstLetterCapital,
  getFullURLPath,
  getShareMsgEbook,
  getShareMsgGK,
  getShareMsgPlacementPaper,
  getSharingCurrentAffairText,
  getSharingTextForJobs,
} from "./constants/constants";

import { useForm } from "react-hook-form";
// import { subscribeNewsLetter } from "../network/APIs/subscribe";
import defaultThumbnail from "./../assets/icons/Default-Icons/icon_thumb.webp";
import defaultList from "./../assets/icons/Default-Icons/icon_list.webp";
// import { subscribeNewsLetter } from "../../network/APIs/subscribe";
// import { postFeedback } from "../../network/APIs/feedback";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { JOB_DETAILS_APP } from "./../shared/constants/routing-constants";
import { subscribeNewsLetter } from "./../network/APIs/subscribe";
import { postFeedback } from "./../network/APIs/feedback";
import { postCampusConnect } from "./../network/APIs/campus";
import { gaEvents } from "./google-analytics-events/ga-events";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavRoutes } from "../pages/constants/constants";
import { getFileNameFromFullURL, isValidUrl, showAlert } from "../shared/utils";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";

const EmptyState = (props: { message?: string; className?: string }) => {
  const { message, className } = props;
  return (
    <div
      className={`flex w-full grow-1 justify-center items-center text-[20px] text-[#7C8493] ${
        className ?? ""
      }`}
    >
      {message ?? "No data available"}
    </div>
  );
};

const PaginationComponent = (props: {
  itemsPerPage: number;
  totalRecords: number;
  handlePageChange: (data: IHandlePageChangeEvent) => void;
  setPageTo?: number;
}) => {
  const { itemsPerPage, totalRecords, handlePageChange, setPageTo } = props;
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    setPageCount(Math.ceil(totalRecords / itemsPerPage));
  }, [itemOffset, itemsPerPage, totalRecords]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalRecords;
    handlePageChange({ page: event.selected + 1 });
    setItemOffset(newOffset);
  };

  return (
    <>
      <ReactPaginate
        className="sm:flex-wrap flex justify-center"
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        forcePage={setPageTo ? setPageTo - 1 : 0}
        renderOnZeroPageCount={undefined}
        containerClassName="list-none flex justify-center items-center"
        activeLinkClassName="text-[16px] font-[600] text-[#FFFFFF] rounded-[8px] bg-[#FF5F14] px-[12px] py-[10px]"
        previousLinkClassName="text-[16px] font-[600] text-[#515B6F] px-[12px] py-[10px]"
        nextLinkClassName="text-[16px] font-[600] text-[#515B6F] px-[12px] py-[10px]"
        pageLinkClassName="text-[16px] font-[600] text-[#515B6F] px-[12px] py-[10px]"
        pageClassName="mb-[10px]"
      />
    </>
  );
};

const downArrawSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className="stroke-2 w-[16px] h-[16] mt-[5px] ml-[2px]"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
    />
  </svg>
);

const Footer = () => {
  const [feedbackBtn, setFeedbackBtn] = useState("Submit");
  const [isLoading, setIsLoading] = useState(false);
  const [successMsgSub, setSuccessMsgSub] = useState(false);
  const [errorMsgSub, setErrorMsgSub] = useState(false);

  const nameRef = React.useRef<any>();
  const emailRef = React.useRef<any>();
  const feedbackRef = React.useRef<any>();
  const feedbackFormRef = React.useRef<any>();

  const { register, handleSubmit, getValues, reset } = useForm();

  const onSubscribeSubmit = (data: any) => {
    subscribeNewsLetter(data)
      .catch(() => {
        setErrorMsgSub(true);
        setTimeout(() => {
          setErrorMsgSub(false);
        }, 3000);
        reset();
      })
      .then(() => {
        setSuccessMsgSub(true);
        setTimeout(() => {
          setSuccessMsgSub(false);
        }, 3000);
        reset();
      });
  };

  const feedbackFormHandler = (e) => {
    e.preventDefault();

    const prepareData = {
      name: nameRef.current.value || "",
      email: emailRef.current.value || "",
      feedback: feedbackRef.current.value || "",
    };

    if (!isLoading) {
      setIsLoading(true);
      postFeedback(prepareData)
        .then((response) => {
          setIsLoading(false);
          if (response.success) {
            feedbackFormRef.current.reset();
            setFeedbackBtn("Response submitted!");
            setTimeout(() => {
              setFeedbackBtn("Submit");
            }, 2000);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${blueFooterBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="relative sm:static flex mt-[72px] sm:mt-[60px] mx-[124px] sm:mx-[0px] h-[414px] sm:bg-none sm:h-[725px] sm:items-center	sm:overflow-hidden sm:bg-blue-700 sm:flex-col-reverse sm:rounded-[6px]"
      >
        <img
          loading="lazy"
          src={blueRightFooterBg}
          alt="logo"
          height={238}
          width={301}
          className="absolute right-[4%] z-[1] sm:hidden"
        />
        <img
          loading="lazy"
          src={blueLeftFooterBg}
          alt="logo"
          height={176}
          width={233}
          className="absolute right-[35%] bottom-[0] z-[1] sm:hidden"
        />
        <img
          loading="lazy"
          src={blueMobileFooterBg}
          alt="mobile icon"
          height="330"
          width="308"
          className="absolute sm:static right-[20%] bottom-[0] sm:mt-[70px]"
        />

        <div className="max-w-[271px] ml-[8%] align self-center sm:flex sm:flex-col sm:ml-0 sm:text-center">
          <h4 className="text-[#FFFFFF] text-[48px] sm:text-[40px] mb-[24px] font-DmSans font-[700]">
            Download App Now
          </h4>
          <p className="text-[#FFFFFF] text-[16px] mb-[24px] font-[500]">
            Government Jobs Application for More Opportunity!
          </p>
          <a
            href="https://play.google.com/store/apps/details?id=com.govtjobs.naukari"
            target="_blank"
            rel="noreferrer noopener"
          >
            <button className="bg-[#FF5F14] px-[24px] py-[12px] text-[#FFFFFF] font-[700]">
              Download from Google Play
            </button>
          </a>
        </div>
      </div>
      <div className="bg-white h-[436px] sm:h-[380px]"></div>

      {/* Get in touch */}
      <div className="bg-[#202430] relative pt-[510px]">
        <form
          ref={feedbackFormRef}
          onSubmit={feedbackFormHandler}
          className="absolute top-[6%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[610px] sm:w-auto sm:min-w-[90vw] bg-white text-center p-[60px] sm:p-[40px] rounded-[30px] shadow-[2px_4px_30px_rgba(0,0,0,0.25)]"
        >
          <div className="mb-[30px] sm:mb-[24px]">
            <p className="text-[36px] sm:text-[30px] mb-[10px] font-[700] font-DmSans">
              Get in Touch
            </p>
            <p className="text-[14px] text-[#858585] font-[500] font-DmSans">
              Our Mission is to empower millions of freshers by delivering jobs
              in a way that has never been experienced before.
            </p>
          </div>
          <input
            placeholder="Full Name"
            type="text"
            required
            name="name"
            autoComplete="off"
            minLength={3}
            className="w-full box-border border border-[#D6DDEB] rounded-[20px] p-[20px] mb-[30px] outline-none"
            ref={nameRef}
          />
          <input
            placeholder="Email Address"
            type="email"
            required
            name="email"
            autoComplete="off"
            minLength={3}
            className="w-full box-border border border-[#D6DDEB] rounded-[20px] p-[20px] mb-[30px] outline-none"
            ref={emailRef}
          />
          <textarea
            name="feedback"
            rows={4}
            placeholder="Comments"
            autoComplete="off"
            required
            minLength={3}
            className="resize-none w-full box-border border border-[#D6DDEB] rounded-[20px] p-[20px] mb-[25px] outline-none"
            ref={feedbackRef}
          />
          <button
            type="submit"
            className="w-full text-white bg-[#2670FF] py-[14px] rounded-[20px] font-[700]"
          >
            {feedbackBtn}
          </button>
        </form>
        <div className="grid grid-cols-12 grid-rows-1 gap-[10%] px-[124px] py-[41px] text-white sm:block sm:px-[60px]">
          <div className="col-span-4">
            <div className="flex items-center mb-[32px]">
              <Link to="/">
                <img
                  loading="lazy"
                  src={freshmentLogoWhite}
                  alt="logo"
                  height={36}
                  width={162}
                />
              </Link>
            </div>
            <p className="text-[16px]">
              Freshment platform help you to connect with recruiters and help
              you to find Jobs in Private Sector Companies as well as will keep
              you updated about Govt Sector also Bank, Railway , Defence, PSU,
              Central and State Government Jobs, Staff selection Commission
              (SSC), Union Public Service Commission(UPSC) etc. and We will help
              you to prepare for exams, daily current affairs & Find best jobs
              for you.
            </p>
          </div>
          <div className="col-span-3 pl-[40px] sm:pl-0 sm:mt-[50px]">
            <p className="font-[600] text-[18px] mb-[32px]">About</p>
            <ul>
              <li className="my-[16px]">
                <Link to="/about">About us</Link>
              </li>
              <li className="my-[16px]">
                <Link to="/terms-conditions">Terms and Conditions</Link>
              </li>
              <li className="my-[16px]">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <div className="col-span-4 sm:mt-[50px]">
            <p className="font-[600] text-[18px] mb-[32px]">
              Get job notifications
            </p>
            <p className="text-[16px]">
              Subscribe to Freshment newsletter to get the latest jobs posted,
              materials, and other latest news stay update.
            </p>
            <form
              className="mt-[24px]"
              onSubmit={handleSubmit(onSubscribeSubmit)}
            >
              <input
                type="text"
                placeholder="Email Address"
                className="w-[14vw] sm:w-[46vw] py-[12px] text-[15px] px-[16px] mr-[8px] mb-[8px] outline-none text-black"
                {...register("email")}
              />
              <button
                type="submit"
                className="bg-[#2670FF] py-[12px] px-[24px] text-[16px] font-[700]"
              >
                Subscribe
              </button>
            </form>
            {successMsgSub && (
              <p className="text-green-500	">
                You have subscribed to the news letters!
              </p>
            )}
            {errorMsgSub && <p className="text-rose-600">Please try again!</p>}
          </div>
        </div>
        <div className="mx-[124px] sm:mx-[60px] h-[2px] bg-[#FFFFFF] bg-opacity-10"></div>
        <div className="flex justify-between items-center py-[42px] px-[124px] sm:px-[60px] sm:block">
          <div className="text-[16px] font-[500] text-[#FFFFFF]">
            {new Date().getFullYear()} @ Freshment. All rights reserved.
          </div>
          <div className="grid grid-cols-12 grid-rows-1 gap-[24px] sm:mt-[20px] sm:gap-[12px]">
            <a
              href="https://www.facebook.com/pages/category/Media-News-Company/Government-Jobs-For-Graduates-112493553872046/"
              target="_blank"
              rel="noreferrer noopener"
              className="col-span-2"
            >
              <img
                loading="lazy"
                src={facebook}
                alt="logo"
                height={32}
                width={32}
              />
            </a>
            <a
              href="https://www.instagram.com/freshmentjobs/"
              target="_blank"
              rel="noreferrer noopener"
              className="col-span-2"
            >
              <img
                loading="lazy"
                src={instagram}
                alt="logo"
                height={32}
                width={32}
              />
            </a>
            {/* <a
              href="https://twitter.com/GovtJobGraduate"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img loading="lazy" src={linkedin} alt="logo" height={32} width={32} />
            </a> */}
            <a
              href="https://twitter.com/freshmentjobs"
              target="_blank"
              rel="noreferrer noopener"
              className="col-span-2"
            >
              <img
                loading="lazy"
                src={twitter}
                alt="logo"
                height={32}
                width={32}
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const Category = (props: IHomePageJobCategories) => {
  const { category_name, id, image_url, total_jobs } = props;

  return (
    <Link
      to={`/explore-job?categoryId=${id}`}
      className="sm:justify-self-center"
    >
      <div className="group box-border h-[250px] w-[274px] sm:h-auto sm:w-[200px] p-[32px] sm:p-[16px] bg-white  border border-[#D6DDEB] hover:bg-blue-600 transition ease-in-out">
        <div className="mb-[32px] rounded-full sm:mb-[14px]">
          <img
            loading="lazy"
            src={getDefaultImage(id)}
            alt="img"
            width={70}
            height={70}
          />
        </div>
        <div>
          <p className="font-bold mb-[12px] text-[24px] sm:text-[20px] text-[#25324B] font-DmSans  group-hover:text-white">
            {category_name}
          </p>
          <div className="flex items-center">
            <p className="text-[18px] sm:text-[14px] text-[#7C8493] mr-[16px] group-hover:text-white">
              {total_jobs} jobs available
            </p>
            <svg
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 stroke-2 group-hover:text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          </div>
        </div>
      </div>
    </Link>
  );
};

const GovJobs = (props: IHomePageGovtJobs) => {
  const {
    company_name,
    post_name,
    image_url,
    job_type,
    job_location,
    name,
    category_name,
    id,
    job_category_id,
  } = props;

  // const router = useRouter();

  const navigate = useNavigate();

  return (
    <div
      className="box-border w-[274px] sm:w-[300px] p-[24px] border sm:justify-self-center bg-white cursor-pointer  border-[#D6DDEB]"
      onClick={() => navigate(`${JOB_DETAILS_APP}/${id}`)}
    >
      <div className="flex justify-between mb-[8px]">
        <div className="overflow-hidden rounded-full h-[40px] w-[40px] min-w-[40px]">
          <img
            loading="lazy"
            src={getDefaultImage(job_category_id)}
            alt="img"
            width={40}
            height={40}
          />
        </div>
        <div className="sm:hidden box-border border border-[#2670FF] text-center h-[34px] py-[4px] px-[12px]">
          <p className="text-[#2670FF] text-[16px]">{job_type}</p>
        </div>
      </div>
      <div className="mb-[16px]">
        <p className="text-[18px] font-[600] text-[#25324B] line-clamp-1">
          {post_name}
        </p>
        <p className="text-[16px] font-[500] text-[#707070] line-clamp-1">
          {company_name}
          {job_location ? ` • ${job_location}` : null}
        </p>
      </div>
      <div className="mb-[16px]">
        <p className="text-[16px]  text-[#7C8493] line-clamp-2">{name}</p>
      </div>
      <div className="flex flex-wrap">
        <div className="bg-[#26A4FF] bg-opacity-10 h-[30px] py-[4px] px-[16px] mb-[6px] mr-[8px] flex justify-center items-center rounded-full">
          <p className="text-[14px] font-[600] text-[#26A4FF]">
            {category_name}
          </p>
        </div>
        {/* <div className="box-border border border-[#2670FF] text-center h-[34px] sm:h-[30px] sm:text-[14px] py-[4px] px-[12px] sm:px-[6px] sm:block sm:min-w-fit">
          <p className="text-[#2670FF] text-[16px] sm:text-[14px]">
            {job_type}
          </p>
        </div> */}
      </div>
    </div>
  );
};

const LatestJobs = (props: IHomePageGovtJobs) => {
  const {
    company_name,
    image_url,
    post_name,
    job_type,
    category_name,
    educational_qualifications,
    id,
    job_category_id,
  } = props;
  // const router = useRouter();
  const navigate = useNavigate();

  return (
    <div
      className="min-w-[300px] py-[24px] px-[40px] sm:px-[20px] sm:pb-[14px] bg-white cursor-pointer"
      onClick={() => navigate(`${JOB_DETAILS_APP}/${id}`)}
    >
      <div className="flex mb-[8px]">
        <div className="overflow-hidden rounded-full mr-[24px] h-[60px] w-[60px] min-w-[60px]">
          <img
            loading="lazy"
            src={getDefaultImage(job_category_id)}
            alt="img"
            width={60}
            height={60}
          />
        </div>
        <div>
          <div className="mb-[16px]">
            <p className="text-[20px] font-[600] text-[#25324B] line-clamp-1">
              {post_name}
            </p>
            <p className="text-[16px] font-[400] text-[#515B6F] line-clamp-1">
              {company_name}
            </p>
          </div>
          <div className="flex flex-wrap">
            <div className="bg-[#56CDAD] bg-opacity-10 h-[30px] sm:mb-[10px] min-w-fit py-[4px] px-[16px] mr-[8px] flex justify-center items-center rounded-full">
              <p className="text-[14px] font-[600] text-[#56CDAD]">
                {job_type}
              </p>
            </div>
            {category_name || educational_qualifications ? (
              <div className="h-[34px] w-[1px] bg-[#D6DDEB] mr-[8px] sm:hidden"></div>
            ) : (
              ""
            )}
            {category_name ? (
              <div className="bg-[#26A4FF] bg-opacity-10 h-[30px] py-[4px] px-[16px] mr-[8px] flex justify-center items-center rounded-full">
                <p className="text-[14px] font-[600] text-[#26A4FF]">
                  {category_name}
                </p>
              </div>
            ) : (
              ""
            )}
            {educational_qualifications ? (
              <div className="bg-[#FF6550] bg-opacity-10 h-[30px] py-[4px] px-[16px] flex justify-center items-center rounded-full">
                <p className="text-[14px] font-[600] text-[#FF6550]">
                  {educational_qualifications}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SearchBar = (
  props:
    | {
        subtitle: string;
        placeholder: string;
        searchString: string;
        handleSearch: (a: string) => void;
      }
    | any
) => {
  const { subtitle, placeholder, searchString, handleSearch } = props;
  const [search, setSearch] = useState<string>(searchString || "");

  // Search button click handler
  const handleSearchBtnClick = (e: any) => {
    e.preventDefault();
    if (handleSearch) {
      handleSearch((search || "").trim().toLowerCase());
    }
  };

  useEffect(() => {
    setSearch(searchString || "");
  }, [searchString]);

  return (
    <>
      <form
        onSubmit={handleSearchBtnClick}
        className="bg-white p-[16px] sm:p-[10px] rounded-[16px] sm:mt-[40px]"
      >
        <div className="w-full flex">
          <div className="flex items-center justify-center ml-[18px]">
            <img
              loading="lazy"
              className="min-w-[24px] sm:w-[18px]"
              src={searchIcon}
              alt="search"
            />
          </div>
          <div className="w-full h-[58px] sm:h-[40px] mx-[16px]">
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-full border-b-2 leading-[56px] sm:leading-[40px] outline-none"
              placeholder={placeholder}
            />
          </div>
          <button
            type="submit"
            onClick={() => handleSearch(search)}
            className="bg-[#2670FF] px-[72px] sm:px-[20px] py-[14px] text-[18px] sm:text-[14px] rounded-[10px] text-white w-[209px] sm:w-auto"
          >
            Search
          </button>
        </div>
      </form>
      <p className="text-[16px] text-[#202430] text-opacity-70 mt-[16px]">
        {subtitle}
      </p>
    </>
  );
};

const GovExamPapers = (props) => {
  const { document_title, category_name, document_name, file_url, mat_id } =
    props;
  const { download, percentage, cancel, isInProgress } = useDownloader();

  const downloadMaterialAnalytics = () => {
    gaEvents.download_material(mat_id);
  };

  useEffect(() => {
    return () => {
      cancel();
    };
  }, []);

  return (
    <div className="p-[24px] bg-white">
      {/* <p className="text-[18px] font-[600] mb-[10px]">
        {document_title || "No title available"}
      </p> */}
      <p className="text-[18px] mb-[10px] line-clamp-3 min-h-[81px]">
        {document_name}
      </p>
      <div className="bg-[#FF6550] bg-opacity-10 max-w-max h-[30px] py-[4px] px-[16px] mb-[20px] flex justify-center items-center rounded-full">
        <p className="text-[14px] font-[600] text-[#FF6550]">
          {category_name || "-"}
        </p>
      </div>
      <button
        onClick={() => {
          if (file_url && isValidUrl(file_url)) {
            download(file_url || "", getFileNameFromFullURL(file_url));
            downloadMaterialAnalytics();
          } else if (file_url && !isValidUrl(file_url)) {
            showAlert("Invalid URL");
          }
        }}
        className={`w-full py-[10px] box-border border border-[#2670FF] rounded-[10px] text-[#2670FF] text-[14px] font-[600] ${
          !file_url ? "cursor-not-allowed grayscale" : ""
        }`}
      >
        {isInProgress ? `Downloading ${percentage}%` : "Download"}
      </button>
    </div>
  );
};

const SharePopover = (props) => {
  const [showLinkCopied, setShowLinkCopied] = useState<boolean>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setShowLinkCopied(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      {React.cloneElement(props.children, { onClick: handleClick })}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ marginTop: "10px" }}
      >
        <div className="flex p-[10px]">
          <CopyToClipboard
            text={props.url}
            onCopy={() => setShowLinkCopied(true)}
          >
            <button className="mx-[10px]">
              {showLinkCopied ? "Link Copied" : "Copy Link"}
            </button>
          </CopyToClipboard>
          <SocialShareButtons msg={props.msg} shareLink={props.url} />
        </div>
      </Popover>
    </div>
  );
};

const CandidateComponent = (props) => {
  const {
    document_title,
    image_url,
    file_url,
    mobile,
    email,
    candidateAnalysis,
    candidate,
  } = props;

  const { download, percentage, cancel, isInProgress } = useDownloader();

  const downloadMaterialAnalytics = () => {
    candidateAnalysis(candidate, "downloadCV");
  };

  const sendEmailAnalytics = () => {
    candidateAnalysis(candidate, "sendEmail");
  };

  useEffect(() => {
    return () => {
      cancel();
    };
  }, []);

  return (
    <div className="py-[24px] px-[40px] bg-white sm:px-[20px] sm:py-[12px]">
      <div className="flex items-center h-full">
        <div className="h-[80px] w-[80px] mr-[10px]">
          <img
            loading="lazy"
            src={image_url || defaultCandidate}
            alt="user profile picture"
            className="items-center rounded-full h-[80px] w-[80px]"
          />
        </div>
        <div className="flex flex-col	justify-between h-full">
          <div>
            <p className="text-[20px] sm:text-[16px] font-[600] text-[#25324B] mb-[8px] break-all">
              {document_title ? document_title : "No title available"}
            </p>
            <p className="text-[16px] font-[400] text-[#515B6F] mb-[8px] break-all inline-block">
              {email ? email : ""}
            </p>
            <div className="flex items-center">
              <p className="text-[16px] font-[400] text-[#515B6F] line-clamp-2">
                {mobile ? mobile : ""}
              </p>
              {mobile && (
                <a href={`tel:${candidate.mobile}`} className="mt-[-2px]">
                  <img
                    loading="lazy"
                    src={callIcon}
                    alt="call icon"
                    className="ml-[10px] h-[32px] w-[32px]"
                  />
                </a>
              )}
            </div>
          </div>
          <div className="flex sm:flex-wrap">
            {file_url ? (
              <>
                <a
                  target="_self"
                  href={"javascript:void(0);"}
                  rel="noopener noreferrer"
                  className="min-w-fit mt-[8px]"
                  onClick={() => {
                    if (file_url && isValidUrl(file_url)) {
                      download(file_url, getFileNameFromFullURL(file_url));
                    } else if(file_url && !isValidUrl(file_url)) {
                      showAlert("Invalid URL");
                    }
                    return false;
                  }}
                >
                  <button
                    onClick={() => downloadMaterialAnalytics()}
                    className="text-[14px] font-[600] mt-[1px] pt-[12px] pb-[8px] px-[12px] mr-[8px] rounded-full bg-[#FF5F14] text-white"
                  >
                    {isInProgress
                      ? `Downloading ${percentage}%`
                      : "Download CV"}
                  </button>
                </a>
                {/* <div className="h-[34px] w-[1px] bg-[#D6DDEB] mr-[8px] sm:hidden"></div> */}
              </>
            ) : (
              false
            )}
            {candidate.email ? (
              <a href={`mailto:${candidate.email}`} className="min-w-fit">
                <button
                  onClick={() => sendEmailAnalytics()}
                  className="mt-[8px] text-[14px] font-[600] pt-[12px] pb-[8px] px-[12px] rounded-full border border-[#FF5F14] text-[#FF5F14]"
                >
                  Send Email
                </button>
              </a>
            ) : (
              false
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Ebook = (props) => {
  const {
    document_title,
    document_name,
    image_url,
    file_url,
    online_url,
    mat_id,
  } = props;

  const { download, percentage, cancel, isInProgress } = useDownloader();

  const downloadMaterialAnalytics = () => {
    gaEvents.download_material(mat_id);
  };

  useEffect(() => {
    return () => {
      cancel();
    };
  }, []);

  return (
    <div className="py-[24px] px-[40px] bg-white sm:px-[20px] sm:py-[12px]">
      <div className="flex">
        <div className="min-w-[98px] mr-[24px] h-[108px] overflow-hidden">
          <img
            loading="lazy"
            src={image_url || bookDefault}
            alt="Book cover"
            width={98}
            height={108}
            className="rounded-[10px]"
          />
        </div>
        <div>
          <p className="text-[20px] sm:text-[16px] font-[600] text-[#25324B] mb-[14px] line-clamp-1">
            {document_title ? document_title : "No title available"}
          </p>
          <p className="text-[12px] font-[400] text-[#515B6F] mb-[14px] line-clamp-2">
            {document_name ? document_name : "-"}
          </p>
          <div className="flex sm:flex-wrap">
            <a
              target={!file_url && online_url ? "_blank" : "_self"}
              href={
                !file_url && online_url ? online_url : "javascript:void(0);"
              }
              rel="noopener noreferrer"
              onClick={() => {
                if (file_url && isValidUrl(file_url)) {
                  download(file_url || "", getFileNameFromFullURL(file_url));
                } else if(file_url && !isValidUrl(file_url)) {
                  showAlert("Invalid URL");
                }
                return false;
              }}
            >
              <button
                onClick={() => downloadMaterialAnalytics()}
                className={`text-[14px] font-[600] mt-[1px] py-[4px] pt-[6px] px-[12px] mr-[8px] rounded-full sm:mb-[6px] bg-[#FF5F14] text-white ${
                  !file_url && !online_url ? "cursor-not-allowed grayscale" : ""
                }`}
              >
                {isInProgress ? `Downloading ${percentage}%` : "Download"}
              </button>
            </a>
            <div className="h-[34px] w-[1px] bg-[#D6DDEB] mr-[8px] sm:hidden"></div>

            <SharePopover
              msg={getShareMsgEbook(file_url || online_url)}
              url={file_url || online_url}
            >
              <button className="text-[14px] font-[600] mt-[1px] py-[4px] pt-[5px] px-[24px] rounded-full border border-[#FF5F14] text-[#FF5F14]">
                Share
              </button>
            </SharePopover>
          </div>
        </div>
      </div>
    </div>
  );
};

const PlacementPapers = (props) => {
  const {
    article_name,
    image_url,
    document_url,
    downloadBtnName,
    online_url,
    default_img,
    componentName,
    mat_id,
  } = props;

  const { download, percentage, cancel, isInProgress } = useDownloader();
  const downloadMaterialAnalytics = () => {
    gaEvents.download_material(mat_id);
  };

  useEffect(() => {
    return () => {
      cancel();
    };
  }, []);
  return (
    <div className="py-[24px] px-[40px] bg-white ">
      <div className="flex">
        <div className="min-w-[64px] mr-[24px] h-[64px]">
          <img
            loading="lazy"
            src={image_url || default_img || defaultList}
            onError={(ev) => ((ev.target as any).src = default_img)}
            alt="Book cover"
            width={64}
            height={64}
            className="rounded-[10px]"
          />
        </div>
        <div>
          <p className="text-[20px] font-[600] text-[#25324B] mb-[14px] line-clamp-1">
            {article_name || "-"}
          </p>
          <div className="flex sm:flex-wrap">
            <a
              target={!document_url && online_url ? "_blank" : "_self"}
              href={
                !document_url && online_url ? online_url : "javascript:void(0);"
              }
              rel="noopener noreferrer"
              onClick={() => {
                if (document_url && isValidUrl(document_url)) {
                  download(
                    document_url || "",
                    getFileNameFromFullURL(document_url)
                  );
                } else if (document_url && !isValidUrl(document_url)) {
                  showAlert("Invalid URL");
                }
              }}
            >
              <button
                onClick={() => downloadMaterialAnalytics()}
                className={`text-[14px] font-[600] py-[6px] min-w-[80px] w-full pt-[8px] px-[10px] mr-[8px] sm:mb-[6px] rounded-full bg-[#FF5F14] text-white ${
                  !online_url && !document_url
                    ? "cursor-not-allowed grayscale"
                    : ""
                }`}
              >
                {downloadBtnName ??
                  (isInProgress ? `Downloading ${percentage}%` : "Download")}
              </button>
            </a>
            <div className="h-[34px] w-[1px] bg-[#D6DDEB] mx-[8px] sm:hidden"></div>

            <SharePopover
              msg={
                componentName === "GK"
                  ? getShareMsgGK(online_url)
                  : getShareMsgPlacementPaper(online_url)
              }
              url={online_url}
            >
              <button className="text-[14px] font-[600] pt-[8px] py-[4px] px-[24px] rounded-full border border-[#FF5F14] text-[#FF5F14]">
                Share
              </button>
            </SharePopover>
          </div>
        </div>
      </div>
    </div>
  );
};

type Anchor = "top" | "left" | "bottom" | "right";

const TemporaryDrawer = () => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: "100vw",
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        paddingTop: "10px",
        paddingLeft: "20px",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box>
        <List>
          {NavRoutes.map((route, index) => (
            <ListItem key={route.route} disablePadding>
              <Link to={route.route}>
                <ListItemButton>
                  <ListItemText
                    sx={{
                      fontSize: "20px",
                    }}
                    primary={route.name}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
          <ListItem disablePadding>
            <Link to="https://play.google.com/store/apps/details?id=com.govtjobs.naukari">
              <ListItemButton>
                <ListItemText
                  sx={{
                    fontSize: "20px",
                  }}
                  primary="Download App"
                />
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
        {/* <Divider />
        <List>
          {["Post Your Job", "Campus Connect", "Download App"].map(
            (text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List> */}
      </Box>
      <Button
        className="hidden sm:mb-[4px] sm:block sm:mt-[10px]"
        onClick={toggleDrawer(anchor, false)}
      >
        <img
          loading="lazy"
          src={closeIcon}
          alt="menu"
          className="w-[24px] h-[24px]"
        />
      </Button>
    </Box>
  );

  return (
    <div>
      {(["left"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            className="hidden sm:mb-[4px] sm:block"
            onClick={toggleDrawer(anchor, true)}
          >
            <img
              loading="lazy"
              src={hamburger}
              alt="menu"
              className="w-[24px] h-[24px]"
            />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

const Header = () => {
  // const router = useRouter();

  const router = useLocation();

  const [isNavbarExpanded, expandNavbar] = useState(true);

  // const [openSignIn, setOpenSignIn] = useState(false);
  // const [openSignUp, setOpenSignUp] = useState(false);
  // const [openForgotPassword, setOpenForgotPassword] = useState(false);

  // const handleClickOpen = () => {
  //   setOpenSignIn(true);
  // };

  // const handleCloseSignIn = () => {
  //   setOpenSignIn(false);
  // };

  // const handleCloseSignUp = () => {
  //   setOpenSignUp(false);
  // };

  // const handleCloseForgotPassword = () => {
  //   setOpenForgotPassword(false);
  // };

  // const handleSignUp = () => {
  //   setOpenSignIn(false);
  //   setOpenSignUp(true);
  // };

  // const handleSignIn = () => {
  //   setOpenSignUp(false);
  //   setOpenSignIn(true);
  // };

  // const handleForgotPassword = () => {
  //   setOpenSignIn(false);
  //   setOpenForgotPassword(true);
  // };

  return (
    <>
      <nav
        className={`flex justify-between items-center pt-3 px-[124px] w-full sm:justify-start sm:px-[2px] nav-bar ${
          router?.pathname === "/" ? "bg-[#F8F8FD]" : "bg-white"
        }`}
      >
        <div className="hidden sm:block">
          <TemporaryDrawer />
        </div>
        <div className="py-2 pb-5 sm:pb-[5px] sm:w-full sm:flex sm:justify-between sm:pr-[10px] sm:mb-[4px]">
          <Link to="/">
            <img
              loading="lazy"
              src={freshmentLogo}
              alt="Logo"
              width={162}
              height={36}
            />
          </Link>

          <a
            href="https://play.google.com/store/apps/details?id=com.govtjobs.naukari"
            target="_blank"
            rel="noreferrer"
            className="hidden sm:block"
          >
            <button className="py-[12px] mt-[-10px] px-[24px] rounded-[10px] bg-[#2670FF] text-white font-[700]">
              Get App
            </button>
          </a>
        </div>
        <ul className="flex gap-[24px] sm:hidden">
          <li>
            <div className="flex justify-center">
              <div>
                <div className="dropdown relative group ">
                  <div
                    className={`text-[#515B6F] font-[500] flex items-center pb-5 pt-[14px] box-border ${
                      router.pathname === "/government-job" ||
                      router.pathname === "/freshers-job" ||
                      router.pathname === "/explore-job"
                        ? "border-b-4 border-b-[#2670FF]"
                        : ""
                    } cursor-pointer`}
                  >
                    Find Jobs
                    <span className="mt-[-4px]">{downArrawSVG}</span>
                  </div>
                  <ul
                    className={`group-hover:block hidden dropdown-menu min-w-max absolute bg-[#F8F8FD] px-[20px] w-[318px] z-50 float-left list-none text-left rounded-[20px] shadow-[1px_10px_10px_rgba(81,91,111,0.25)] m-0 bg-clip-padding border-none`}
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <Link to="/government-job">
                      <li className="dropdown-item py-[20px] font-[500] border-b border-[#A1A2A3] block w-full whitespace-nowrap text-gray-700">
                        Government Job
                      </li>
                    </Link>
                    <Link to="/freshers-job">
                      <li className="dropdown-item py-[20px] font-[500] border-b border-[#A1A2A3] block w-full whitespace-nowrap text-gray-700">
                        Freshers Job
                      </li>
                    </Link>
                    <Link to="/explore-job">
                      <li className="dropdown-item py-[20px] font-[500] block w-full whitespace-nowrap text-gray-700">
                        Explore Job
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li
            className={`text-[#515B6F] font-[500] pb-5 pt-[14px] box-border ${
              router.pathname == "/current-affairs" ||
              router.pathname == "/current-affairs/[id]"
                ? "border-b-4 border-b-[#2670FF]"
                : ""
            }`}
          >
            <Link to="/current-affairs">Current Affairs</Link>
          </li>
          <li
            className={`text-[#515B6F] font-[500] pb-5 pt-[14px] box-border ${
              router.pathname == "/study-material"
                ? "border-b-4 border-b-[#2670FF]"
                : ""
            }`}
          >
            <Link to="/study-material">Study Material</Link>
          </li>
          <li
            className={`text-[#515B6F] font-[500] pb-5 pt-[14px] box-border ${
              router.pathname == "/post-your-job"
                ? "border-b-4 border-b-[#2670FF]"
                : ""
            }`}
          >
            <Link to="/post-your-job">Post Your Job</Link>
          </li>
        </ul>
        <div className="flex gap-[16px] btns sm:hidden">
          <Link to="/campus-connect">
            <button className="py-[12px] mt-[-10px] px-[24px] box-border border-2 rounded-[10px] border-[#FF5F14] text-[#FF5F14] font-[500]">
              Campus Connect
            </button>
          </Link>
          <a
            href="https://play.google.com/store/apps/details?id=com.govtjobs.naukari"
            target="_blank"
            rel="noreferrer"
          >
            <button className="py-[12px] mt-[-10px] px-[24px] rounded-[10px] bg-[#2670FF] text-white font-[700]">
              Download App
            </button>
          </a>

          {/* <button className="text-[#515B6F] font-[500] mt-[-10px]" onClick={handleClickOpen}>
          Sign in
        </button> */}
          {/* <SignIn
          openSignIn={openSignIn}
          handleCloseSignIn={handleCloseSignIn}
          handleSignUp={handleSignUp}
          handleForgotPassword={handleForgotPassword}
        />
        <SignUp
          openSignUp={openSignUp}
          handleCloseSignUp={handleCloseSignUp}
          handleSignIn={handleSignIn}
        />
        <ForgotPassword
          openForgotPassword={openForgotPassword}
          handleCloseForgotPassword={handleCloseForgotPassword}
        /> */}
        </div>
      </nav>
    </>
  );
};

const MenuItem = (props: {
  item: IMenuItem;
  selectedTab: number;
  onTabSelect: (id: number, value: string) => void;
}) => {
  const { item, selectedTab, onTabSelect } = props;
  return (
    <div
      className="bg-white cursor-pointer max-w-fit py-[10px] px-[20px] sm:px-[16px] rounded-[10px] flex justify-center items-center shadow-[0px_2px_4px_rgba(0,0,0,0.25)] sm:min-w-fit"
      onClick={() => onTabSelect(item.id, item.value)}
    >
      <p
        className={`${
          selectedTab === item.id ? "text-[#FF5F14]" : "text-[#8E94A3]"
        } text-[20px] sm:text-[16px] font-[700] font-DmSans`}
      >
        {item.name}
      </p>
    </div>
  );
};

const AllJobs = (props: IGovtJobsList | any) => {
  const {
    id,
    name,
    image_url,
    company_name,
    apply_online_url,
    post_name,
    description,
    salary,
    total_job_applicants,
    job_type,
    category_name,
    job_location,
    job_title,
    job_category_id,
    isGovtJob,
  } = props;
  const LPA = +(salary ? salary : 0) / 100000;

  // const router = useRouter();
  const navigate = useNavigate();

  const handleApplyNow = (e) => {
    e.stopPropagation();
    navigate(`/${JOB_DETAILS_APP}/${id}`);
  };
  return (
    <div className="py-[30px] px-[50px] sm:px-[34px] sm:w-[calc(100vw-80px)] rounded-[10px] bg-white">
      <div className="flex mb-[14px]">
        <div className="overflow-hidden mr-[22px] h-[64px] w-[64px]">
          <img
            loading="lazy"
            src={image_url || getDefaultImage(job_category_id)}
            alt="author"
            width={64}
            height={64}
          />
        </div>
        <div className="mb-[16px]">
          <p className="text-[20px] font-[600] text-[#25324B] line-clamp-1">
            {isGovtJob ? name : post_name ? post_name : name}
          </p>
          <p className="text-[16px] font-[500] text-[#515B6F] line-clamp-1">
            {company_name && job_location
              ? company_name + ", " + job_location
              : company_name
              ? company_name
              : job_location
              ? job_location
              : ""}
          </p>
        </div>
      </div>
      <div
        className="mb-[19px] line-clamp-3 text-[14px] font-[400] text-[#515B6F]"
        style={{
          display: "-webkit-box",
          overflow: "hidden",
          fontSize: "1rem",
          lineHeight: "1.5rem",
          height: "4.5rem",
        }}
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
      <div className="flex flex-wrap">
        <div className="bg-[#56CDAD] sm:mb-[10px] bg-opacity-10 py-[6px] px-[10px] mr-[8px] flex justify-center items-center rounded-full">
          <p className="flex gap-[8px] text-[14px] font-[600] text-[#56CDAD]">
            <img loading="lazy" src={suitcase} alt="Logo" width={20} />
            {job_type}
          </p>
        </div>
        <div className="py-[6px] sm:mb-[10px] px-[10px] mr-[8px] border border-[#FFB836] flex justify-center items-center rounded-full">
          <p className="text-[14px] font-[600] text-[#FFB836]">
            {category_name}
          </p>
        </div>
      </div>
      <div className="h-[1px] w-full bg-[#DEDEDE] mt-[19px]"></div>
      <div className="flex justify-between mt-[28px] sm:block">
        <div className="flex gap-[50px] sm:gap-[10px] sm:flex-col sm:mb-[20px]">
          <p className="flex items-center">
            <img
              loading="lazy"
              src={currencyRupee}
              alt="Logo"
              className="mt-[-6px] w-[34px] h-[34px] sm:w-[26px] sm:h-[26px]"
            />
            {LPA ? (
              <span className="text-[#000000] text-[20px] sm:text-[14px] font-[700] ml-[10px] mr-1">
                {LPA}
              </span>
            ) : (
              ""
            )}
            <span className="text-[#515B6F] text-[18px] sm:text-[14px] font-[400] mt-[2px]">
              {LPA ? (
                "LPA"
              ) : (
                <span className="ml-[8px]">Industry Standards</span>
              )}
            </span>
          </p>
          <p className="flex items-center">
            <img
              loading="lazy"
              src={biPeopleFill}
              alt="Logo"
              className="mt-[-8px] w-[30px] h-[30px] sm:w-[24px] sm:h-[24px]"
            />
            <span className="text-[#000000] text-[20px] sm:text-[14px] font-[700] ml-[14px] mr-1">
              {total_job_applicants}
            </span>
            <span className="text-[#515B6F] sm:text-[14px] text-[18px] font-[400]">
              People applied
            </span>
          </p>
        </div>
        <div>
          <button
            onClick={(e) => handleApplyNow(e)}
            className="text-[16px] sm:text-[14px] font-[700] py-[12px] cursor-pointer px-[40px] sm:px-[30px] rounded-[10px] bg-[#FF5F14] text-white"
          >
            View Job
          </button>
        </div>
      </div>
    </div>
  );
};

const DetailedJob = (props: IJobData) => {
  const { details, total_job_applicants } = props;
  const LPA = +(details?.salary || 0) / 100000;

  return (
    <div className="bg-white py-[72px]">
      <div className="grid grid-cols-3 gap-[64px] sm:block">
        <div className="col-span-2 sm:mb-[60px]">
          <div>
            <h3 className="text-[#25324B] text-[32px] mb-[20px] font-[700] font-DmSans">
              Job Description
            </h3>
            <div className=" job-description-text">
              <div
                dangerouslySetInnerHTML={{ __html: details.description }}
              ></div>
            </div>
          </div>

          {details?.job_required_skills && (
            <div className="mt-[72px]">
              <h3 className="text-[#25324B] text-[32px] mb-[20px] font-[700] font-DmSans">
                Required Skills
              </h3>
              <div className=" job-description-text">
                <div
                  dangerouslySetInnerHTML={{
                    __html: details?.job_required_skills,
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="sm:mb-[60px]">
            <h3 className="text-[#25324B] text-[32px] font-[700] font-DmSans">
              Job Overview
            </h3>
            <div className="flex justify-between my-[23px]">
              <span className="text-[#515B6F]">Total Applicants</span>
              <span className="text-[#25324B] font-[600]">
                {total_job_applicants}
              </span>
            </div>
            <div className="flex justify-between my-[23px]">
              <span className="text-[#515B6F]">Apply Before</span>
              <span className="text-[#25324B] font-[600]">
                {format(new Date(details.application_end_date), "MMM dd, yyyy")}
              </span>
            </div>
            <div className="flex justify-between my-[23px]">
              <span className="text-[#515B6F]">Job Posted On</span>
              <span className="text-[#25324B] font-[600]">
                {format(new Date(details.created_at), "MMM dd, yyyy")}
              </span>
            </div>
            <div className="flex justify-between my-[23px]">
              <span className="text-[#515B6F]">Job Type</span>
              <span className="text-[#25324B] font-[600]">
                {getFirstLetterCapital(details.job_type)}
              </span>
            </div>
            <div className="flex justify-between my-[23px]">
              <span className="text-[#515B6F]">Salary</span>
              <span className="text-[#25324B] font-[600]">
                {LPA ? "₹" + LPA + " LPA/Year" : "Industry Standards"}
              </span>
            </div>
          </div>
          <div className="h-[1px] w-full bg-[#D6DDEB] my-[40px] sm:hidden"></div>
          <div>
            <h3 className="text-[#25324B] text-[32px] font-[700] mb-[23px] font-DmSans">
              Categories
            </h3>
            <div className="flex gap-[8px]">
              <div className="bg-[#FFB836] bg-opacity-10 h-[30px] py-[6px] px-[10px] flex justify-center items-center rounded-full">
                <p className="text-[14px] font-[600] text-[#FFB836]">
                  Freshers
                </p>
              </div>
              <div className="bg-[#56CDAD] bg-opacity-10 h-[30px] py-[6px] px-[10px] flex justify-center items-center rounded-full">
                <p className="text-[14px] font-[600] text-[#56CDAD]">
                  Marketing
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SocialShareButtons = (props: { shareLink: string; msg: string }) => {
  const { shareLink, msg } = props;

  return (
    <div className="flex gap-[14px] mx-[14px]">
      <FacebookShareButton
        children={
          <div>
            <img
              loading="lazy"
              src={color_facebook}
              alt="logo"
              height={30}
              width={30}
            />
          </div>
        }
        url={shareLink}
        quote={msg}
      />
      <TwitterShareButton
        children={
          <img
            loading="lazy"
            src={color_twitter}
            alt="logo"
            height={30}
            width={30}
          />
        }
        url={msg}
      />
      <LinkedinShareButton
        children={
          <img
            loading="lazy"
            src={color_linkedin}
            alt="logo"
            height={30}
            width={30}
          />
        }
        summary={msg}
        url={shareLink}
      />
    </div>
  );
};

const JobHeader = (props: IJobData) => {
  const { details, total_job_applicants } = props;

  const apply_online_analytics = () => {
    gaEvents.apply_online_job(+details.id, +details.job_category_id);
  };

  const send_resume_job = () => {
    gaEvents.apply_online_job(+details.id, +details.job_category_id);
  };

  const call_hr_job = () => {
    gaEvents.apply_online_job(+details.id, +details.job_category_id);
  };

  const view_advt_job = () => {
    gaEvents.apply_online_job(+details.id, +details.job_category_id);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${jobDescHeaderBg})`,
        height: "auto",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: "#F8F8FD",
      }}
      className="px-[124px] pt-[88px] pb-[40px] sm:px-[40px] sm:pt-[50px]"
    >
      <div className="flex mb-[40px] sm:block">
        <div className="overflow-hidden mr-[35px] min-w-[170px] min-h-[170px] max-w-[170px] max-h-[170px] sm:max-h-[100px] sm:max-w-[100px] sm:min-h-[100px] sm:min-w-[100px] sm:mb-[20px]">
          <img
            loading="lazy"
            src={details.image_url || getDefaultImage(details.job_category_id)}
            alt="Company Logo"
            className="min-w-[170px] min-h-[170px] max-w-[170px] max-h-[170px] sm:max-h-[100px] sm:max-w-[100px] sm:min-h-[100px] sm:min-w-[100px]"
          />
        </div>
        <div>
          <h2 className="text-[#25324B] text-[40px] font-[700] sm:text-[34px] mb-[13px] font-DmSans">
            {details.name}
          </h2>
          <div className="flex items-center mb-[27px]">
            <p className="text-[#2670FF] text-[20px] font-[500] mr-[10px] font-DmSans">
              {total_job_applicants} people applied
            </p>

            <SharePopover
              url={getFullURLPath()}
              msg={getSharingTextForJobs(getFullURLPath())}
            >
              <img loading="lazy" src={share} alt="Logo" width={37} />
            </SharePopover>
          </div>
          <ul className="flex gap-[40px] sm:block flex-wrap">
            {details.company_name ? (
              <li className="flex items-center text-[#25324B] font-[600]">
                <img
                  loading="lazy"
                  src={smallBuilding}
                  alt="Logo"
                  width={44}
                  height={44}
                  className="mr-[16px]"
                />
                {details.company_name}
              </li>
            ) : (
              ""
            )}
            {details.job_location ? (
              <li className="flex items-center text-[#25324B] font-[600]">
                <img
                  loading="lazy"
                  src={smallLocation}
                  alt="Logo"
                  width={44}
                  height={44}
                  className="mr-[16px]"
                />
                {details.job_location}
              </li>
            ) : (
              ""
            )}
            {details.category_name ? (
              <li className="flex items-center text-[#25324B] font-[600]">
                <img
                  loading="lazy"
                  src={smallSuitcase}
                  alt="Logo"
                  width={44}
                  height={44}
                  className="mr-[16px]"
                />
                {details.category_name}
              </li>
            ) : (
              ""
            )}
            {details.job_type ? (
              <li className="flex items-center text-[#25324B] font-[600]">
                <img
                  loading="lazy"
                  src={smallWatch}
                  alt="Logo"
                  width={44}
                  height={44}
                  className="mr-[16px]"
                />
                {getFirstLetterCapital(details.job_type)}
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>
      <div>
        {details.contact_email ? (
          <a href={`mailto:${details.contact_email}`}>
            <button
              onClick={() => send_resume_job()}
              className="text-[16px] mb-[10px] font-[700] py-[12px] px-[42px] sm:px-[30px] rounded-[10px] bg-[#FE7231] text-white mr-[20px]"
            >
              Send Resume
            </button>
          </a>
        ) : details.apply_online_url ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={details.apply_online_url || "javascript:void(0);"}
          >
            <button
              onClick={() => apply_online_analytics()}
              className="text-[16px] mb-[10px] font-[700] py-[12px] px-[42px] sm:px-[30px] rounded-[10px] bg-[#FE7231] text-white mr-[20px]"
            >
              Apply Now
            </button>
          </a>
        ) : null}

        {details.advertisement_url ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={details.advertisement_url}
          >
            <button
              onClick={() => view_advt_job()}
              className="py-[12px] px-[40px] sm:px-[30px] box-border border bg-white border-[#2670FF] rounded-[10px] text-[#2670FF] text-[16px] font-[700]"
            >
              View Advertisement
            </button>
          </a>
        ) : details.contact_number ? (
          <a href={`tel:${details.contact_number}`}>
            <button
              onClick={() => call_hr_job()}
              className="py-[12px] px-[40px] sm:px-[30px] box-border border bg-white border-[#2670FF] rounded-[10px] text-[#2670FF] text-[16px] font-[700]"
            >
              Call Now
            </button>
          </a>
        ) : null}
      </div>
    </div>
  );
};

const CurrenAffairs = (props: ICurrentAffairs) => {
  const {
    // id,
    article_name_english,
    short_desc,
    created_on,
    image_url,
    // reference_url,
  } = props;

  const newDate = new Date(created_on);

  return (
    <div
      className="bg-white rounded-[10px] cursor-pointer shadow hover:shadow-lg
      transition ease-in-out duration-150 hover:scale-[1.02]"
    >
      <div
        style={{
          backgroundImage: `url(${image_url || defaultThumbnail})`,
          height: "250px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="rounded-[10px] overflow-hidden	"
      ></div>
      <div className="px-[14px] pb-[27px] pt-[10px]">
        {created_on ? (
          <div className="flex items-center gap-[6px]">
            <img
              loading="lazy"
              className="rounded-t-lg"
              src={clock}
              alt="clock"
              width={16}
            />
            <span className="text-[#515B6F] text-[12px] font-[400]">
              {newDate.getDate() +
                "/" +
                (newDate.getMonth() + 1) +
                "/" +
                newDate.getFullYear()}
            </span>
          </div>
        ) : (
          ""
        )}
        <h5 className="text-[24px] font-[700] my-[10px] line-clamp-1 font-DmSans">
          {article_name_english}
        </h5>
        <p className="text-[#515B6F] font-[400] line-clamp-6">
          {short_desc}...
        </p>
      </div>
    </div>
  );
};

const RecentAffairs = (props: ICurrentAffairs) => {
  // const router = useRouter();

  const {
    article_name_english,
    short_desc,
    created_on,
    image_url,
    // reference_url,
    id,
  } = props;

  const newDate = new Date(created_on);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigateToDetailPage = (currentAffairId: number) => {
    const keys = searchParams.keys();
    for (const key of keys) {
      searchParams.delete(key);
    }
    searchParams.append("currentAffairId", currentAffairId + "");
    setSearchParams(searchParams);
  };

  return (
    <div
      className="grid sm:block sm:mb-[20px] grid-cols-12 grid-rows-1 bg-white rounded-[10px] cursor-pointer shadow hover:shadow-lg
      transition ease-in-out duration-150 hover:scale-[1.02]"
      onClick={() => navigateToDetailPage(+id)}
    >
      <div
        style={{
          backgroundImage: `url(${image_url || defaultThumbnail})`,
          height: "152px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="col-span-4 rounded-[10px]"
      ></div>
      <div className="pl-[24px] pr-[14px] pb-[17px] pt-[10px] col-span-8">
        {created_on ? (
          <div className="flex items-center gap-[6px]">
            <img
              loading="lazy"
              className="rounded-t-lg"
              src={clock}
              alt="clock"
              width={16}
            />
            <span className="text-[#515B6F] text-[12px] font-[400]">
              {newDate.getDate() +
                "/" +
                (newDate.getMonth() + 1) +
                "/" +
                newDate.getFullYear()}
            </span>
          </div>
        ) : (
          ""
        )}
        <h5 className="text-[18px] font-[700] my-[10px] line-clamp-1 font-DmSans">
          {article_name_english}
        </h5>
        <p className="text-[#515B6F] text-[12px] font-[400] line-clamp-3">
          {short_desc}.....
        </p>
      </div>
    </div>
  );
};

const DetailedAffair = (props: ICurrentAffairDetails | any) => {
  const { id, image_url, article_name_english, content_english, created_on } =
    props;

  const newDate = new Date(created_on);

  return (
    <>
      <h2 className="text-[#25324B] text-[36px] font-[700] mb-[20px] font-DmSans">
        {article_name_english}
      </h2>
      <div className="bg-white rounded-[10px] grow-1">
        <div className="overflow-hidden h-[445px] sm:h-auto">
          <img
            loading="lazy"
            src={image_url || defaultThumbnail}
            alt={image_url || defaultThumbnail}
            height="100%"
            width="100%"
          />
        </div>
        <div className="px-[14px] pt-[10px]">
          {created_on ? (
            <div className="flex items-center gap-[6px] mb-[10px]">
              <img
                loading="lazy"
                className="rounded-t-lg"
                src={clock}
                alt="clock"
                width={16}
                height={16}
              />
              <span className="text-[#515B6F] text-[12px] font-[400]">
                {newDate.getDate() +
                  "/" +
                  (newDate.getMonth() + 1) +
                  "/" +
                  newDate.getFullYear()}
              </span>
            </div>
          ) : (
            ""
          )}
          <div
            className="text-[#515B6F] font-[400]"
            dangerouslySetInnerHTML={{ __html: content_english }}
          ></div>
          <div className="h-[1px] w-full bg-[#DEDEDE] mt-[50px] mb-[20px]"></div>
          <div className="flex items-center pb-[50px]">
            <p className="text-[#515B6F] font-[400] mr-[34px]">
              Share Link on:
            </p>
            <div className="flex gap-[14px]">
              <SocialShareButtons
                msg={getSharingCurrentAffairText(getFullURLPath())}
                shareLink={getFullURLPath()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const RecentPosts = (props: ICurrentAffairs) => {
  const {
    article_name_english,
    created_on,
    reference_url,
    id,
    handleRecentPostClick,
  } = props;

  const newDate = new Date(created_on);

  return (
    <div
      className="bg-white w-[224px] sm:w-auto p-[14px] rounded-[10px] cursor-pointer"
      onClick={() => handleRecentPostClick(+id)}
    >
      {created_on ? (
        <div className="flex items-center gap-[6px] mb-[10px]">
          <img
            loading="lazy"
            className="rounded-t-lg"
            src={clock}
            alt="Image"
            width={16}
          />
          <span className="text-[#515B6F] text-[12px] font-[400]">
            {newDate.getDate() +
              "/" +
              (newDate.getMonth() + 1) +
              "/" +
              newDate.getFullYear()}
          </span>
        </div>
      ) : (
        ""
      )}
      <h4 className="text-[#25324B] text-[18px] font-[700] line-clamp-2">
        {article_name_english}
      </h4>
    </div>
  );
};

const JobFilter = (props: {
  filters: IFiltersGroup[];
  handleFilterChange: (a: IFiltersGroup[]) => void;
  emitSearchString?: (search: string) => void;
  hideTitle?: boolean;
}) => {
  const { filters, handleFilterChange, emitSearchString, hideTitle } = props;
  const [appliedFilters, setAppliedFilters] = useState<IFiltersGroup[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");

  useEffect(() => {
    setAppliedFilters(filters);
  }, [filters]);

  useEffect(() => {
    handleFilterChange(appliedFilters);
  }, [appliedFilters]);

  // Search button click handler
  const handleSearchBtnClick = (e: any) => {
    e.preventDefault();
    if (emitSearchString) {
      emitSearchString((searchInput || "").trim().toLowerCase());
    }
  };

  // Clear button handler
  const handleClearSection = (sectionName: string, clearAll?: boolean) => {
    setAppliedFilters((prev) => {
      return prev.map((filterGroup) => {
        if (clearAll) {
          filterGroup.filters.forEach((filter) => {
            filter.isChecked = false;
          });
        } else if (filterGroup.label === sectionName) {
          filterGroup.filters.forEach((filter) => {
            filter.isChecked = false;
          });
        }
        return filterGroup;
      });
    });
    if (clearAll && emitSearchString) {
      emitSearchString("");
      setSearchInput("");
    }
  };

  // Filter option change handler
  const handleFilterOptionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    category_group_name: string
  ) => {
    setAppliedFilters((prev) => {
      return prev.map((filterGroup) => {
        if (filterGroup.label === category_group_name) {
          const indexOfOption = filterGroup.filters.findIndex(
            (option) => option.id === Number(e.target.id)
          );
          if (filterGroup.type === "radio") {
            filterGroup.filters.forEach((filter) => {
              filter.isChecked = false;
            });

            if (indexOfOption >= 0) {
              filterGroup.filters[indexOfOption].isChecked = Boolean(
                e.target.value
              );
            }
          }

          if (filterGroup.type === "checkbox" && indexOfOption >= 0) {
            filterGroup.filters[indexOfOption].isChecked = Boolean(
              e.target.checked
            );
          }
        }
        return filterGroup;
      });
    });
  };

  return (
    <div className="grid gap-[20px] w-full">
      {hideTitle ? (
        ""
      ) : (
        <div className="bg-white flex justify-between rounded-[10px] py-[34px] px-[40px]">
          <h4 className="text-[#FF5F14] text-[20px] font-[500]">Job Filter</h4>
          <button
            className="text-[#515B6F] text-[14px] font-[500]"
            type="button"
            onClick={() => handleClearSection("", true)}
          >
            Clear all
          </button>
        </div>
      )}
      {emitSearchString ? (
        <div className="bg-white py-[12px] px-[10px] rounded-[10px]">
          <form onSubmit={handleSearchBtnClick}>
            <div className="w-full flex gap-[10px]">
              <div className="flex items-center justify-center">
                <img
                  loading="lazy"
                  className="min-w-[16px]"
                  src={searchIcon}
                  height={16}
                  alt="search"
                />
              </div>
              <div className="w-full">
                <input
                  className="w-full border-b-2 leading-[37px] outline-none"
                  name="search"
                  autoComplete="off"
                  placeholder="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="bg-[#FF5F14] px-[20px] py-[10px] text-[12px] font-[700] rounded-[10px] text-white w-[84px]"
              >
                Search
              </button>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}

      {appliedFilters.map((filter) => (
        <div
          className="bg-white py-[34px] px-[40px] rounded-[10px]"
          key={filter?.label}
        >
          <div className="bg-white flex justify-between pb-[24px]">
            <h4 className="text-[#FF5F14] text-[18px] font-[500]">
              {filter.label}
            </h4>
            <button
              type="button"
              className="text-[#515B6F] text-[14px] font-[500]"
              onClick={(e) => {
                handleClearSection(filter.label);
              }}
            >
              Clear
            </button>
          </div>
          <div className="flex flex-col gap-[24px] rounded-[10px]">
            {filter.filters.map((items) => (
              <label
                key={items.id}
                className="text-[#707070] font-[400] flex items-center cursor-pointer"
              >
                {filter.type === "radio" && (
                  <input
                    type={filter.type}
                    id={`${items.id}`}
                    value={items.value}
                    name={`${items.name}`}
                    onChange={(e) => handleFilterOptionChange(e, filter.label)}
                    className="h-[18px] w-[18px] mr-[7px]"
                  />
                )}

                {filter.type === "checkbox" && (
                  <input
                    type={filter.type}
                    id={`${items.id}`}
                    value={items.value}
                    name={`${items.name}`}
                    checked={items.isChecked}
                    onChange={(e) => handleFilterOptionChange(e, filter.label)}
                    className="h-[18px] w-[18px] mr-[7px]"
                  />
                )}

                {items.lable}
              </label>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const MobileFilters = (props) => {
  const { filters, handleFilterChange, emitSearchString, hideTitle } = props;

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: "calc(100vw - 22px)",
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column-reverse",
        paddingTop: "10px",
        paddingLeft: "20px",
      }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <JobFilter {...props} />
      <Button
        className="hidden sm:mb-[4px] sm:block sm:mt-[10px]"
        onClick={toggleDrawer(anchor, false)}
      >
        <img
          loading="lazy"
          src={closeIcon}
          alt="menu"
          className="w-[24px] h-[24px]"
        />
      </Button>
    </Box>
  );

  return (
    <div>
      {(["bottom"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            className="hidden sm:mb-[4px] sm:block"
            onClick={toggleDrawer(anchor, true)}
          >
            <div className="hidden sm:flex gap-[4px]">
              <img loading="lazy" src={filter} alt="" width={24} />
              <p className="text-[#707070] font-[600]">Filter</p>
            </div>
            {/* <img loading="lazy" src={hamburger} alt="menu" className="w-[24px] h-[24px]" /> */}
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export {
  Category,
  GovJobs,
  LatestJobs,
  Footer,
  Header,
  GovExamPapers,
  MenuItem,
  AllJobs,
  CurrenAffairs,
  RecentAffairs,
  JobHeader,
  DetailedJob,
  RecentPosts,
  DetailedAffair,
  SearchBar,
  PlacementPapers,
  Ebook,
  JobFilter,
  PaginationComponent,
  EmptyState,
  SocialShareButtons,
  CandidateComponent,
  MobileFilters,
};
