import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { postCampusConnect } from "../../network/APIs/campus";
import campusBg from "./../../assets/images/campusBg.webp";
import user from "./../../assets/icons/user.svg";
import contact from "./../../assets/icons/contact.svg";
import email from "./../../assets/icons/email.svg";
import building from "./../../assets/icons/building.svg";
import bi_people from "./../../assets/icons/bi_people.svg";

const Campus = () => {
  const [campusSubmitBtn, setCampusSubmitBtn] = useState("Submit");
  const [isLoading, setIsLoading] = useState(false);

  const campusConnectFormRef = React.useRef<any>();

  const campusConnectFormHandler = (e) => {
    e.preventDefault();

    const prepareData = {
      name: campusConnectFormRef.current.name.value || "",
      email: campusConnectFormRef.current.email.value || "",
      contact_no: campusConnectFormRef.current.contact.value || "",
      institute_name: campusConnectFormRef.current.institute.value || "",
      no_of_students: campusConnectFormRef.current.totalStudents.value || "",
    };

    if (!isLoading) {
      setIsLoading(true);
      postCampusConnect(prepareData)
        .then((response) => {
          setIsLoading(false);
          if (response.success) {
            campusConnectFormRef.current.reset();
            setCampusSubmitBtn("Response submitted!");
            setTimeout(() => {
              setCampusSubmitBtn("Submit");
            }, 2000);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${campusBg})`,
          height: "922px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="pt-[124px] sm:pt-[80px] sm:ml-[40px] ml-[124px] w-[490px] sm:w-auto">
          <p className="text-[50px] sm:text-[34px] font-[700] text-[#25324B] font-DmSans">
            Connect To Campus
          </p>
          <p className="text-[22px] font-[500] text-[#707070]">
            We will get back to you soon
          </p>
          <form ref={campusConnectFormRef} onSubmit={campusConnectFormHandler}>
            <div className="grid grid-rows-6 grid-cols-1 gap-[24px] mt-[36px] sm:w-[calc(100vw-80px)]">
              <div className="relative">
                <img
loading="lazy"                    src={user}
                  alt="user"
                  height={24}
                  width={24}
                  className="absolute top-5 left-5"
                />
                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  minLength={3}
                  className="w-full pl-[60px] p-[20px] border border-[#D6DDEB] rounded-[10px] outline-none shadow-[1px_4px_10px_rgba(0,0,0,0.25)]"
                  placeholder="Full Name"
                  autoComplete="off"
                />
              </div>
              <div className="relative">
                <img
loading="lazy"                    src={contact}
                  alt="user"
                  height={24}
                  width={24}
                  className="absolute top-5 left-5"
                />
                <input
                  id="contact"
                  name="contact"
                  type="text"
                  minLength={10}
                  maxLength={10}
                  onChange={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/gi, ""))
                  }
                  className="w-full pl-[60px] p-[20px] border border-[#D6DDEB] rounded-[10px] outline-none shadow-[1px_4px_10px_rgba(0,0,0,0.25)]"
                  placeholder="Contact Number"
                  autoComplete="off"
                  required
                />
              </div>
              <div className="relative">
                <img
loading="lazy"                    src={email}
                  alt="user"
                  height={24}
                  width={24}
                  className="absolute top-5 left-5"
                />
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  minLength={3}
                  className="w-full pl-[60px] p-[20px] border border-[#D6DDEB] rounded-[10px] outline-none shadow-[1px_4px_10px_rgba(0,0,0,0.25)]"
                  placeholder="Email Address"
                  autoComplete="off"
                />
              </div>
              <div className="relative">
                <img
loading="lazy"                    src={building}
                  alt="user"
                  height={24}
                  width={24}
                  className="absolute top-5 left-5"
                />
                <input
                  id="institute"
                  name="institute"
                  type="text"
                  minLength={3}
                  required
                  className="w-full pl-[60px] p-[20px] border border-[#D6DDEB] rounded-[10px] outline-none shadow-[1px_4px_10px_rgba(0,0,0,0.25)]"
                  placeholder="Name of Institute"
                  autoComplete="off"
                />
              </div>
              <div className="relative">
                <img
loading="lazy"                    src={bi_people}
                  alt="user"
                  height={24}
                  width={24}
                  className="absolute top-5 left-5"
                />
                <input
                  id="totalStudents"
                  name="totalStudents"
                  type="number"
                  required
                  className="w-full pl-[60px] p-[20px] border border-[#D6DDEB] rounded-[10px] outline-none shadow-[1px_4px_10px_rgba(0,0,0,0.25)]"
                  placeholder="Number of Students"
                  autoComplete="off"
                />
              </div>
              <button
                type="submit"
                className="bg-[#2670FF] h-[57px] text-[18px] font-[700] rounded-[10px] text-white shadow-[1px_4px_10px_rgba(0,0,0,0.25)]"
              >
                {campusSubmitBtn}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

function CampusConnect() {
  return (
    <>
      <Helmet>
        <title>Campus Connect</title>
        <meta name="description" content="Campus Connect" />
      </Helmet>
      <Campus />
    </>
  );
}

export default CampusConnect;
