const categories = [
  {
    icon: "M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5",
    title: "Engineering",
    totalJobs: "542",
  },
  {
    icon: "M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5",
    title: "Engineering",
    totalJobs: "542",
  },
  {
    icon: "M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5",
    title: "Engineering",
    totalJobs: "542",
  },
  {
    icon: "M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5",
    title: "Engineering",
    totalJobs: "542",
  },
  {
    icon: "M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5",
    title: "Engineering",
    totalJobs: "542",
  },
  {
    icon: "M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5",
    title: "Engineering",
    totalJobs: "542",
  },
  {
    icon: "M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5",
    title: "Engineering",
    totalJobs: "542",
  },
  {
    icon: "M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5",
    title: "Engineering",
    totalJobs: "542",
  },
];

const govExamPaper = [
  {
    title: "GPSC Exam Paper",
    discription:
      "Lorem Ipsum is simply dummy text of the dsaa printing sseedew",
  },
  {
    title: "GPSC Exam Paper",
    discription:
      "Lorem Ipsum is simply dummy text of the dsaa printing sseedew",
  },
  {
    title: "GPSC Exam Paper",
    discription:
      "Lorem Ipsum is simply dummy text of the dsaa printing sseedew",
  },
  {
    title: "GPSC Exam Paper",
    discription:
      "Lorem Ipsum is simply dummy text of the dsaa printing sseedew",
  },
  {
    title: "GPSC Exam Paper",
    discription:
      "Lorem Ipsum is simply dummy text of the dsaa printing sseedew",
  },
  {
    title: "GPSC Exam Paper",
    discription:
      "Lorem Ipsum is simply dummy text of the dsaa printing sseedew",
  },
  {
    title: "GPSC Exam Paper",
    discription:
      "Lorem Ipsum is simply dummy text of the dsaa printing sseedew",
  },
  {
    title: "GPSC Exam Paper",
    discription:
      "Lorem Ipsum is simply dummy text of the dsaa printing sseedew",
  },
  {
    title: "GPSC Exam Paper",
    discription:
      "Lorem Ipsum is simply dummy text of the dsaa printing sseedew",
  },
  {
    title: "GPSC Exam Paper",
    discription:
      "Lorem Ipsum is simply dummy text of the dsaa printing sseedew",
  },
  {
    title: "GPSC Exam Paper",
    discription:
      "Lorem Ipsum is simply dummy text of the dsaa printing sseedew",
  },
  {
    title: "GPSC Exam Paper",
    discription:
      "Lorem Ipsum is simply dummy text of the dsaa printing sseedew",
  },
];

const studyMaterialMenu = [
  {
    id: 1,
    name: "E-Book",
  },
  {
    id: 2,
    name: "Govt Exam Syallbus",
  },
  {
    id: 3,
    name: "Govt Exam Papers",
  },
  {
    id: 4,
    name: "Placement Papers",
  },
  {
    id: 5,
    name: "GK Resources",
  },
];


const exploreJobTabs = [
  {
    id: 1,
    name: "Graduates",
    value: "UG"
  },
  {
    id: 2,
    name: "Post Graduates",
    value: "PG"
  },
  {
    id: 3,
    name: "Diploma",
    value: "DIPLOMA"
  },
  {
    id: 4,
    name: "ITI",
    value: "ITI"
  },
  {
    id: 5,
    name: "HSC",
    value: "HSC"
  },
  {
    id: 6,
    name: "SSC",
    value: "SSC"
  },
];

const govJobs = [
  {
    title: "SSC Head Constable",
    department: "Ministerial",
    location: "Delhi",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and",
    package: "4.8",
    applied: "25",
  },
  {
    title: "SSC Head Constable",
    department: "Ministerial",
    location: "Delhi",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and",
    package: "4.8",
    applied: "25",
  },
  {
    title: "SSC Head Constable",
    department: "Ministerial",
    location: "Delhi",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and",
    package: "4.8",
    applied: "25",
  },
  {
    title: "SSC Head Constable",
    department: "Ministerial",
    location: "Delhi",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and",
    package: "4.8",
    applied: "25",
  },
  {
    title: "SSC Head Constable",
    department: "Ministerial",
    location: "Delhi",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and",
    package: "4.8",
    applied: "25",
  },
  {
    title: "SSC Head Constable",
    department: "Ministerial",
    location: "Delhi",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and",
    package: "4.8",
    applied: "25",
  },
  {
    title: "SSC Head Constable",
    department: "Ministerial",
    location: "Delhi",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and",
    package: "4.8",
    applied: "25",
  },
  {
    title: "SSC Head Constable",
    department: "Ministerial",
    location: "Delhi",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and",
    package: "4.8",
    applied: "25",
  },
  {
    title: "SSC Head Constable",
    department: "Ministerial",
    location: "Delhi",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and",
    package: "4.8",
    applied: "25",
  },
];

const recentAffairs = [
  {
    title: "Real-Time Train Information System",
    date: "April 05, 2022",
    description:
      "The RTIS was jointly developed by the Indian Railways and Indian Space Research Organisation (ISRO).It is currently being executed by the Centre for Railway.....",
  },
  {
    title: "Real-Time Train Information System",
    date: "April 05, 2022",
    description:
      "The RTIS was jointly developed by the Indian Railways and Indian Space Research Organisation (ISRO).It is currently being executed by the Centre for Railway.....",
  },
  {
    title: "Real-Time Train Information System",
    date: "April 05, 2022",
    description:
      "The RTIS was jointly developed by the Indian Railways and Indian Space Research Organisation (ISRO).It is currently being executed by the Centre for Railway.....",
  },
];

const DATE_POSTED_FILTERS = {
  label: "Date Posted",
  value: "date_posted",
  type: "radio",
  filters: [
    { id: 1, lable: "Last 7 days", value: "7Days", name: "date" },
    { id: 2, lable: "Last 14 days", value: "14Days", name: "date" },
    { id: 3, lable: "Last 30 days", value: "30Days", name: "date" },
    // { id: 4, lable: "All", name: "date" },
  ],
};

const QUALIFICATION_FILTERS = {
  label: "Qualification",
  value: "qualification",
  type: "checkbox",
  filters: [
    { id: 1, lable: "UG", value: "UG", name: "ug" },
    { id: 2, lable: "PG", value: "PG", name: "pg" },
    { id: 3, lable: "SSC", value: "SSC", name: "ssc" },
    { id: 4, lable: "ITI", value: "ITI", name: "iti" },
    { id: 5, lable: "HSC", value: "HSC", name: "hsc" },
    { id: 6, lable: "DIPLOMA", value: "DIPLOMA", name: "diploma" },
    { id: 7, lable: "ICWA/CA", value: "ICWA/CA", name: "icwa/ca" },
    { id: 8, lable: "DOCTORS", value: "DOCTORS", name: "doctors" },
    { id: 9, lable: "PHD", value: "PHD", name: "phd" },
    { id: 10, lable: "OTHER", value: "OTHER", name: "other" },
  ],
};


const NavRoutes = [
  { name: 'Government Jobs', route: '/government-job' },
  { name: 'Freshers Jobs', route: '/freshers-job' },
  { name: 'Explore Jobs', route: '/explore-job' },
  { name: 'Current Affairs', route: '/current-affairs' },
  { name: 'Study Materials', route: '/study-material' },
  { name: 'Post Your Job', route: '/post-your-job' },
  { name: 'Campus Connect', route: '/campus-connect' },
  // { name: 'Download App', route: '' }
]

module.exports = {
  categories,
  govExamPaper,
  studyMaterialMenu,
  exploreJobTabs,
  govJobs,
  recentAffairs,
  DATE_POSTED_FILTERS,
  QUALIFICATION_FILTERS,
  NavRoutes
};
