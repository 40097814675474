import React from "react";
import AboutView from "../pages/about";
import AppliedJobDetails from "../pages/applied-job-details";
import CampusConnect from "../pages/campus-connect";
import CurrentAffairs from "../pages/current-affairs";
import ExploreJob from "../pages/explore-job";
import FreshersJob from "../pages/freshers-job";
import GovernmentJob from "../pages/government-job";
import HomePage from "../pages/homePage";
import JobDescription from "../pages/job-description";
import PostYourJob from "../pages/postyourjob";
import PrivacyPolicyPageView from "../pages/privacy-policy";
import StudyMaterial from "../pages/study-material";
import TermandCondition from "../pages/terms-conditions";

const mainRoutes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "government-job",
    element: <GovernmentJob />,
  },
  {
    path: "freshers-job",
    element: <FreshersJob />,
  },
  {
    path: "explore-job",
    element: <ExploreJob />,
  },
  {
    path: "post-your-job",
    element: <PostYourJob />,
  },
  {
    path: "current-affairs",
    element: <CurrentAffairs />,
  },
  {
    path: "study-material",
    element: <StudyMaterial />,
  },
  {
    path: "terms-conditions",
    element: <TermandCondition />,
  },
  {
    path: "campus-connect",
    element: <CampusConnect />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicyPageView />,
  },
  {
    path: "about",
    element: <AboutView />,
  },
  {
    path: "job-description",
    // element: <JobDescription />,
    children: [
      {
        path: ":id",
        element: <JobDescription />,
      },
    ],
  },
  {
    path: "candidate-listing",
    children: [
      {
        path: ":companyId",
        children: [
          {
            path: ":job_id",
            element: <AppliedJobDetails />,
          },
        ],
      },
    ],
  },
  // {
  //   path: "products",
  //   children: [
  //     {
  //       index: true,
  //       element: <Products />,
  //     },
  //     {
  //       path: ":productId",
  //       element: <Product />,
  //     },
  //   ],
  // },
];

export default mainRoutes;
