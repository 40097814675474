import {
  useCurrentAffairDetails,
  useFeaturedCurrentAffairs,
} from "../network/APIs/current-affairs";
import { DetailedAffair, RecentPosts } from ".";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { convertDateTothFormat, convertHtMLtoText } from "../shared/utils";

const CurrentAffairsDetailsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentAffairId = searchParams.get("currentAffairId");
  const [dateConverted, setDateConverted] = useState<string>()

  const { data: currentAffairDetails } = useCurrentAffairDetails(
    currentAffairId as string
  );
  const { data: topCurrentAffairsList } = useFeaturedCurrentAffairs();

  const handleRecentPostClick = (currentAffairId: number) => {
    const keys = searchParams.keys();
    for (const key of keys) {
      searchParams.delete(key);
    }
    searchParams.append("currentAffairId", currentAffairId + "");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (currentAffairDetails?.created_on) {
      setDateConverted(convertDateTothFormat(currentAffairDetails?.created_on));
    }
  }, [currentAffairDetails]);

  return (
    <>
      <Helmet>
        <title>{`${
          currentAffairDetails?.article_name_english || "Current Affairs"
        }, Daily GK Update: ${dateConverted}`}</title>
        <meta
          name="title"
          content={`${
            currentAffairDetails?.article_name_english || "Current Affairs"
          }, Daily GK Update: ${dateConverted}`}
        />
        <meta
          name="description"
          content={`${
            convertHtMLtoText(currentAffairDetails?.content_english)?.slice(
              0,
              120
            ) + "..." ||
            currentAffairDetails?.article_name_english ||
            "Current Affairs"
          } Daily GK Update: ${dateConverted} | freshmentapp.com`}
        />
        <meta
          name="image"
          content="https://freshmentapp.com/static/media/freshmentLogo.a5200e9f.svg"
        />

        <link
          rel="canonical"
          href="https://freshmentapp.com/current-affairs?currentAffairId=5319"
        />
        <link
          rel="alternate"
          href="https://freshmentapp.com/current-affairs?currentAffairId=5319"
          hrefLang="en-in"
        />
        <meta name="author" content="freshment" />
        <meta property="business:contact_data:locality" content="Rajkot" />
        <meta property="business:contact_data:postal_code" content="360002" />
        <meta property="business:contact_data:country_name" content="India" />
        <meta
          property="business:contact_data:email"
          content="contact@freshmentapp.com"
        />
        <meta
          property="business:contact_data:website"
          content="https://freshmentapp.com"
        />
        <meta
          itemProp="name"
          content="Freshment - Find a candidate in 72 hours, Find a job in 7 days"
        />
        <meta property="profile:username" content="freshment" />
        <meta
          itemProp="description"
          content={`${
            convertHtMLtoText(currentAffairDetails?.content_english)?.slice(
              0,
              120
            ) + "..." ||
            currentAffairDetails?.article_name_english ||
            "Current Affairs"
          } Daily GK Update: ${dateConverted} | freshmentapp.com`}
        />
        <meta
          property="og:title"
          content={`${
            currentAffairDetails?.article_name_english || "Current Affairs"
          }, Daily GK Update: ${dateConverted}`}
        />

        <meta name="author" content="freshment" />
        <meta property="business:contact_data:locality" content="Rajkot" />
        <meta property="business:contact_data:postal_code" content="360002" />
        <meta property="business:contact_data:country_name" content="India" />
        <meta
          property="business:contact_data:email"
          content="contact@freshmentapp.com"
        />
        <meta
          property="business:contact_data:website"
          content="https://freshmentapp.com"
        />
        <meta
          itemProp="name"
          content="Freshment - Find a candidate in 72 hours, Find a job in 7 days"
        />
        <meta property="profile:username" content="freshment" />
        <meta
          itemProp="description"
          content={`${
            convertHtMLtoText(currentAffairDetails?.content_english)?.slice(
              0,
              120
            ) + "..." ||
            currentAffairDetails?.article_name_english ||
            "Current Affairs"
          } Daily GK Update: ${dateConverted} | freshmentapp.com`}
        />

        <meta
          property="og:title"
          content={`${
            currentAffairDetails?.article_name_english || "Current Affairs"
          }, Daily GK Update: ${dateConverted}`}
        />

        <meta
          name="og:description"
          content={`${
            convertHtMLtoText(currentAffairDetails?.content_english)?.slice(
              0,
              120
            ) + "..." ||
            currentAffairDetails?.article_name_english ||
            "Current Affairs"
          } Daily GK Update: ${dateConverted} | freshmentapp.com`}
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://freshmentapp.com" />
        <meta
          property="og:determiner"
          content="Post Jobs To Get Candidates | Hire Staff Easily In 72 Hours"
        />
        <meta
          name="twitter:title"
          content={`${
            currentAffairDetails?.article_name_english || "Current Affairs"
          }, Daily GK Update: ${dateConverted}`}
        />

        <meta
          name="twitter:description"
          content={`${
            convertHtMLtoText(currentAffairDetails?.content_english)?.slice(
              0,
              120
            ) + "..." ||
            currentAffairDetails?.article_name_english ||
            "Current Affairs"
          } Daily GK Update: ${dateConverted} | freshmentapp.com`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="freshment" />
        <meta
          name="twitter:image"
          content="https://freshmentapp.com/static/media/about-office-1.1b443800.png"
        />
        <meta name="robots" content="index,follow" />

        <meta name="google" content="notranslate" />
        <meta http-equiv="Content-Language" content="en_US" />

        <meta
          name="keywords"
          content="Current Affairs 2023, daily Current Affairs 2023, latest Current Affairs, current affairs today, gk today, gk updates, monthly current affairs, quiz gk, general knowledge, gk today current affairs, gk studies, upsc 2023, gpsc 2023, general awareness"
        />
      </Helmet>
      <div className="flex bg-[#F8F8FD] px-[124px] sm:block sm:px-[40px] gap-[42px] py-[40px] justify-between">
        {currentAffairDetails ? (
          <div className="grow-1">
            <DetailedAffair {...currentAffairDetails} />
          </div>
        ) : (
          ""
        )}
        <div className="mt-[76px]">
          <h4 className="text-[#25324B] text-[24px] font-[700] mb-[30px] font-DmSans">
            Recent Posts
          </h4>
          <div className="grid gap-[30px]">
            {topCurrentAffairsList?.map((affair) => (
              <RecentPosts
                {...affair}
                handleRecentPostClick={handleRecentPostClick}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentAffairsDetailsPage;
