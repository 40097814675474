import React from "react";
import {
  Box,
  Container,
  createTheme,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Link } from "@mui/material";
import './privacy-policy.css'

const PrivacyPolicyPageView = () => {
  const theme = createTheme({
    typography: {
      fontFamily: "Epilogue",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Box className="privacy-policy-container">
          <Box className="terms-conditions-container">
            {/* TERMS & CONDITIONS */}
            <Typography variant="h1" className="font-DmSans">
              Privacy Policy
            </Typography>
            <Typography className="f16-28">
              Thank you for choosing to be part of our community at Freshment
              (“<span className="bold-text">Company</span>”, “
              <span className="bold-text">we</span>”, “
              <span className="bold-text">us</span>”, or “
              <span className="bold-text">our</span>”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at support@freshmentapp.com.
            </Typography>
            <br />

            <br className="br" />
            <Typography className="f16-28">
              When you visit our website{" "}
              <Link target="_blank" href="http://www.freshmentapp.com/">
                http://www.freshmentapp.com
              </Link>
              (the "<span className="bold-text">Website</span>"), use our mobile
              application, as the case may be (the "
              <span className="bold-text">App</span>") and more generally, use
              any of our services (the "
              <span className="bold-text">Services</span>", which include the Website and App), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.
            </Typography>
            <br />

            <br className="br" />
            <Typography className="f16-28">
              This privacy notice applies to all information collected through
              our Services (which, as described above, includes our Website and
              App), as well as any related services, sales, marketing or events.
            </Typography>
            <br />

            {/* LICENSE */}
            <Typography className="note">
              This privacy notice applies to all information collected through our Services (which, as described above, includes our Website and App), as well as any related services, sales, marketing or events.
            </Typography>
            <br />
            <br />

            <Typography className="contents-head">TABLE OF CONTENTS</Typography>

            <Typography className="questions">
              <Link
                target="_blank"
                href="https://freshmentapp.com/privacy-policy#infocollect"
              >
                1. WHAT INFORMATION DO WE COLLECT?
              </Link>
            </Typography>
            <Typography className="questions">
              <Link
                target="_blank"
                href="https://freshmentapp.com/privacy-policy#infouse"
              >
                2. HOW DO WE USE YOUR INFORMATION?
              </Link>
            </Typography>
            <Typography className="questions">
              <Link
                target="_blank"
                href="https://freshmentapp.com/privacy-policy#infoshare"
              >
                3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
              </Link>
            </Typography>
            <Typography className="questions">
              <Link
                target="_blank"
                href="https://freshmentapp.com/privacy-policy#sociallogins"
              >
                4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
              </Link>
            </Typography>
            <Typography className="questions">
              <Link
                target="_blank"
                href="https://freshmentapp.com/privacy-policy#3pwebsites"
              >
                5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
              </Link>
            </Typography>
            <Typography className="questions">
              <Link
                target="_blank"
                href="https://freshmentapp.com/privacy-policy#inforetain"
              >
                6. HOW LONG DO WE KEEP YOUR INFORMATION?
              </Link>
            </Typography>
            <Typography className="questions">
              <Link
                target="_blank"
                href="https://freshmentapp.com/privacy-policy#infosafe"
              >
                7. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </Link>
            </Typography>
            <Typography className="questions">
              <Link
                target="_blank"
                href="https://freshmentapp.com/privacy-policy#privacyrights"
              >
                8. WHAT ARE YOUR PRIVACY RIGHTS?
              </Link>
            </Typography>
            <Typography className="questions">
              <Link
                target="_blank"
                href="https://freshmentapp.com/privacy-policy#DNT"
              >
                9. CONTROLS FOR DO-NOT-TRACK FEATURES
              </Link>
            </Typography>
            <Typography className="questions">
              <Link
                target="_blank"
                href="https://freshmentapp.com/privacy-policy#caresidents"
              >
                10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </Link>
            </Typography>
            <Typography className="questions">
              <Link
                target="_blank"
                href="https://freshmentapp.com/privacy-policy#policyupdates"
              >
                11. DO WE MAKE UPDATES TO THIS NOTICE?
              </Link>
            </Typography>
            <Typography className="questions">
              <Link
                target="_blank"
                href="https://freshmentapp.com/privacy-policy#contact"
              >
                12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </Link>
            </Typography>
            <br />

            {/* THIS BOX FOR QUESTION 1 */}
            <Box className="que-info" id="infocollect">
              <Typography className="que-head">
                1. WHAT INFORMATION DO WE COLLECT?
              </Typography>
              <Typography className="sub-head">
                Personal information you disclose to us
              </Typography>
              <Typography className="sub-head">
                <span className="in-short-head">In Short:</span>
                <span className="in-short-italic">
                  We collect information that you provide to us.
                </span>
              </Typography>
              <Typography className="que-describe">
                We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services or otherwise when you contact us.{" "}
              </Typography>
              <Typography className="que-describe">
                The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the products and features you use. The personal information we collect may include the following:
              </Typography>
              <Typography className="sub-head">
                Personal Information Provided by You.
                <span className="in-short">
                  We collect names; email addresses; phone numbers; usernames; passwords; and other similar information.
                </span>
              </Typography>
              <Typography className="sub-head">
                Social Media Login Data.
                <span className="in-short">
                  We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter or other social media account. If you choose to register in this way, we will collect the information described in the section called 
                  <Link
                    target="_blank"
                    href="https://www.freshmentapp.com/privacy-policy#sociallogins"
                  >
                    {" "}HOW DO WE HANDLE YOUR SOCIAL LOGINS
                  </Link> below.
                </span>
              </Typography>
              {/* <Typography className="sub-head">
                Company (Register As Service Provider) Information Provided by
                You.
                <span className="in-short">
                  We collect names; email addresses; phone numbers; usernames;
                  passwords; company licence; company verification documents and
                  other similar information.
                </span>
              </Typography>
              <Typography className="que-describe">
                All personal information that you provide to us must be true,
                complete and accurate, and you must notify us of any changes to
                such personal information.
              </Typography> */}
              <Typography className="sub-head">
                Information automatically collected
              </Typography>
              <Typography className="sub-head">
                <span className="in-short-head">In Short:</span>
                <span className="in-short-italic">
                  Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
                </span>
              </Typography>
              <Typography className="que-describe">
                We automatically collect certain information when you visit, use or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about who and when you use our Services and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
              </Typography>
              <Typography className="que-describe">
                The information we collect includes:
              </Typography>
              <Typography className="que-describe">
                ●<span className="light-italic "> Log and Usage Data.</span> Log and usage data is service-related, diagnostic usage and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).
              </Typography>
              <Typography className="sub-head">
                Information collected through our App
              </Typography>
              <Typography className="sub-head">
                <span className="in-short-head">In Short:</span>
                <span className="in-short-italic">
                  We collect information regarding your push notifications, when
                  you use our App.
                </span>
              </Typography>
              <Typography className="que-describe">
                If you use our App, we also collect the following information:
              </Typography>

              <Typography className="que-describe">
                ● <span className="light-italic "> Push Notifications.</span>
                We may request to send you push notifications regarding your account or certain features of the App. If you wish to opt-out from receiving these types of communications, you may turn them off in your device's settings.
              </Typography>
              <Typography className="que-describe">
                The information is primarily needed to maintain the security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes.
              </Typography>
            </Box>

            {/* THIS BOX FOR QUESTION 2 */}
            <Box className="que-info" id="infouse">
              <Typography className="que-head">
                2. HOW DO WE USE YOUR INFORMATION?
              </Typography>
              <Typography className="sub-head">
                <span className="in-short-head">In Short:</span>
                <span className="in-short-italic">
                  We process your information for purposes based on legitimate
                  business interests, the fulfillment of our contract with you,
                  compliance with our legal obligations, and/or your consent.
                </span>
              </Typography>
              <Typography className="que-describe">
                We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
              </Typography>
              <Typography className="que-describe">
                We use the information we collect or receive:
              </Typography>
              <Typography className="sub-head">
                ● To send administrative information to you.
                <span className="in-short">
                  We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.
                </span>
              </Typography>
              <Typography className="sub-head">
                ● To protect our Services.
                <span className="in-short">
                  We may use your information as part of our efforts to keep our Services safe and secure (for example, for fraud monitoring and prevention).
                </span>
              </Typography>
              <Typography className="sub-head">
                ● To enforce our terms, conditions and policies for business
                purposes, to comply with legal and regulatory requirements or in
                connection with our contract.
              </Typography>
              <Typography className="sub-head">
                ● To respond to legal requests and prevent harm.
                <span className="in-short">
                  If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.
                </span>
              </Typography>
            </Box>

            {/* THIS BOX FOR QUESTION 3 */}
            <Box className="que-info" id="infoshare">
              <Typography className="que-head">
                3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
              </Typography>
              <Typography className="sub-head">
                <span className="in-short-head">In Short:</span>
                <span className="in-short-italic">
                  We only share information with your consent, to comply with
                  laws, to provide you with services, to protect your rights, or
                  to fulfill business obligations.
                </span>
              </Typography>
              <Typography className="que-describe">
                We may process or share your data that we hold based on the
                following legal basis:
              </Typography>
              <Typography className="sub-head">
                ● Consent:
                <span className="in-short">
                  We may process your data if you have given us specific consent
                  to use your personal information in a specific purpose.
                </span>
              </Typography>
              <Typography className="sub-head">
                ● Legitimate Interests:
                <span className="in-short">
                  We may process your data when it is reasonably necessary to
                  achieve our legitimate business interests.
                </span>
              </Typography>
              <Typography className="sub-head">
                ● Performance of a Contract:
                <span className="in-short">
                  Where we have entered into a contract with you, we may process
                  your personal information to fulfill the terms of our
                  contract.
                </span>
              </Typography>
              <Typography className="sub-head">
                ● Legal Obligations:
                <span className="in-short">
                  We may disclose your information where we are legally required
                  to do so in order to comply with applicable law, governmental
                  requests, a judicial proceeding, court order, or legal
                  process, such as in response to a court order or a subpoena
                  (including in response to public authorities to meet national
                  security or law enforcement requirements).
                </span>
              </Typography>
              <Typography className="sub-head">
                ● Vital Interests:
                <span className="in-short">
                  We may disclose your information where we believe it is
                  necessary to investigate, prevent, or take action regarding
                  potential violations of our policies, suspected fraud,
                  situations involving potential threats to the safety of any
                  person and illegal activities, or as evidence in litigation in
                  which we are involved.
                </span>
              </Typography>
              <Typography className="que-describe">
                More specifically, we may need to process your data or share
                your personal information in the following situations:
              </Typography>
              <Typography className="sub-head">
                ● Business Transfers.
                <span className="in-short">
                  We may share or transfer your information in connection with,
                  or during negotiations of, any merger, sale of company assets,
                  financing, or acquisition of all or a portion of our business
                  to another company.
                </span>
              </Typography>
            </Box>

            {/* THIS BOX FOR QUESTION 4 */}
            <Box className="que-info" id="sociallogins">
              <Typography className="que-head">
                4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
              </Typography>
              <Typography className="sub-head">
                <span className="in-short-head">In Short:</span>
                <span className="in-short-italic">
                  If you choose to register or log in to our services using a social media account, we may have access to certain information about you.
                </span>
              </Typography>
              <Typography className="que-describe">
                Our Services offers you the ability to register and login using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile Information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, profile picture as well as other information you choose to make public on such social media platform.
              </Typography>
              <Typography className="que-describe">
                We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.
              </Typography>
            </Box>

            {/* THIS BOX FOR QUESTION 5 */}
            <Box className="que-info" id="3pwebsites">
              <Typography className="que-head">
                5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
              </Typography>
              <Typography className="sub-head">
                <span className="in-short-head">In Short:</span>
                <span className="in-short-italic">
                  We are not responsible for the safety of any information that
                  you share with third-party providers who advertise, but are
                  not affiliated with, our Website.
                </span>
              </Typography>
              <Typography className="que-describe">
                The Services may contain advertisements from third parties that are not affiliated with us and which may link to other websites, online services or mobile applications. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions.
              </Typography>
            </Box>

            {/* THIS BOX FOR QUESTION 6 */}
            <Box className="que-info" id="inforetain">
              <Typography className="que-head">
                6. HOW LONG DO WE KEEP YOUR INFORMATION?
              </Typography>
              <Typography className="sub-head">
                <span className="in-short-head">In Short:</span>
                <span className="in-short-italic">
                  We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
                </span>
              </Typography>
              <Typography className="que-describe">
                We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than 2 years.
              </Typography>
              <Typography className="que-describe">
                When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
              </Typography>
            </Box>

            {/* THIS BOX FOR QUESTION 7 */}
            <Box className="que-info" id="infosafe">
              <Typography className="que-head">
                7. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </Typography>
              <Typography className="sub-head">
                <span className="in-short-head">In Short:</span>
                <span className="in-short-italic">
                  We aim to protect your personal information through a system
                  of organisational and technical security measures.
                </span>
              </Typography>
              <Typography className="que-describe">
                We have implemented appropriate technical and organisational
                security measures designed to protect the security of any
                personal information we process. However, despite our safeguards
                and efforts to secure your information, no electronic
                transmission over the Internet or information storage technology
                can be guaranteed to be 100% secure, so we cannot promise or
                guarantee that hackers, cybercriminals, or other unauthorised
                third parties will not be able to defeat our security, and
                improperly collect, access, steal, or modify your information.
                Although we will do our best to protect your personal
                information, transmission of personal information to and from
                our Services is at your own risk. You should only access the
                Services within a secure environment.
              </Typography>
            </Box>
            {/* THIS BOX FOR QUESTION 8 */}
            <Box className="que-info" id="privacyrights">
              <Typography className="que-head">
                8. WHAT ARE YOUR PRIVACY RIGHTS?
              </Typography>
              <Typography className="sub-head">
                <span className="in-short-head">In Short:</span>
                <span className="in-short-italic">
                  You may review, change, or terminate your account at any time.
                </span>
              </Typography>
              <Typography className="que-describe">
                If you are resident in the European Economic Area and you
                believe we are unlawfully processing your personal information,
                you also have the right to complain to your local data
                protection supervisory authority. You can find their contact
                details here: <span> </span>
                <Link
                  target="_blank"
                  href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm."
                >
                  http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                </Link>
              </Typography>
              <Typography className="que-describe">
                If you are resident in Switzerland, the contact details for the
                data protection authorities are available here:<span> </span>
                <Link target="_blank" sx={{ wordBreak: "break-all" }} href="https://www.edoeb.admin.ch/edoeb/en/home.html">
                  https://www.edoeb.admin.ch/edoeb/en/home.html
                </Link>
              </Typography>
            </Box>

            {/* THIS BOX FOR QUESTION 9 */}
            <Box className="que-info" id="DNT">
              <Typography className="que-head">
                9. CONTROLS FOR DO-NOT-TRACK FEATURES
              </Typography>
              <Typography className="que-describe">
                Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
              </Typography>
            </Box>

            {/* THIS BOX FOR QUESTION 10 */}
            <Box className="que-info" id="caresidents">
              <Typography className="que-head">
                10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </Typography>
              <Typography className="sub-head">
                <span className="in-short-head">In Short:</span>
                <span className="in-short-italic">
                  Yes, if you are a resident of California, you are granted
                  specific rights regarding access to your personal information.
                </span>
              </Typography>
              <Typography className="que-describe">
                California Civil Code Section 1798.83, also known as the “Shine
                The Light” law, permits our users who are California residents
                to request and obtain from us, once a year and free of charge,
                information about categories of personal information (if any) we
                disclosed to third parties for direct marketing purposes and the
                names and addresses of all third parties with which we shared
                personal information in the immediately preceding calendar year.
                If you are a California resident and would like to make such a
                request, please submit your request in writing to us using the
                contact information provided below.
              </Typography>
              <Typography className="que-describe">
                If you are under 18 years of age, reside in California, and have
                a registered account with a Service, you have the right to
                request removal of unwanted data that you publicly post on the
                Services. To request removal of such data, please contact us
                using the contact information provided below, and include the
                email address associated with your account and a statement that
                you reside in California. We will make sure the data is not
                publicly displayed on the Services, but please be aware that the
                data may not be completely or comprehensively removed from all
                our systems (e.g. backups, etc.).{" "}
              </Typography>
            </Box>

            {/* THIS BOX FOR QUESTION 11 */}
            <Box className="que-info" id="policyupdates">
              <Typography className="que-head">
                11. DO WE MAKE UPDATES TO THIS NOTICE?
              </Typography>
              <Typography className="sub-head">
                <span className="in-short-head">In Short:</span>
                <span className="in-short-italic">
                  Yes, we will update this notice as necessary to stay compliant
                  with relevant laws.
                </span>
              </Typography>
              <Typography className="que-describe">
                We may update this privacy notice from time to time. The updated
                version will be indicated by an updated “Revised” date and the
                updated version will be effective as soon as it is accessible.
                If we make material changes to this privacy notice, we may
                notify you either by prominently posting a notice of such
                changes or by directly sending you a notification. We encourage
                you to review this privacy notice frequently to be informed of
                how we are protecting your information.
              </Typography>
            </Box>

            {/* THIS BOX FOR QUESTION 12 */}
            <Box className="que-info" id="contact">
              <Typography className="que-head">
                12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </Typography>
              <Typography className="que-describe">
                If you have questions or comments about this notice, you may email us at support@governmentjobsforgraduates.com or by post to:
              </Typography>
              <Typography className="que-describe">
                Freshment <br />
                India
              </Typography>

              <Typography className="que-head">
                HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                YOU?
              </Typography>
              <Typography className="que-describe">
                Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please visit: support@governmentjobsforgraduates.com. We will respond to your request within 30 days.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default PrivacyPolicyPageView;
