import ReactGA from "react-ga4";

const viewJob = (jobId: number, catId: number) =>
  ReactGA.event("view_job", {
    action: "view_job",
    category: catId,
    value: jobId,
  });

const apply_online_job = (jobId: number, catId: number) =>
  ReactGA.event("apply_online_job", {
    action: "apply_online_job",
    category: catId,
    value: jobId,
  });

const view_advt_job = (jobId: number, catId: number) =>
  ReactGA.event("view_advt_job", {
    action: "view_advt_job",
    category: catId,
    value: jobId,
  });

const send_resume_job = (jobId: number, catId: number) =>
  ReactGA.event("send_resume_job", {
    action: "send_resume_job",
    category: catId,
    value: jobId,
  });

const call_hr_job = (jobId: number, catId: number) =>
  ReactGA.event("call_hr_job", {
    action: "call_hr_job",
    category: catId,
    value: jobId,
  });

const download_material = (materialId: number) =>
  ReactGA.event("download_material", {
    action: "download_material",
    category: "download_material",
    value: materialId,
  });

const gaEvents = {
  apply_online_job,
  viewJobEvent: viewJob,
  view_advt_job,
  send_resume_job,
  call_hr_job,
  download_material,
};

export { gaEvents };
