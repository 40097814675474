import { useParams } from "react-router-dom";
import { DetailedJob, JobHeader } from "../../shared-components";
import { useJobDetails } from "../../network/APIs/jobs";
import { Helmet } from "react-helmet";
import { getFirstLetterCapital } from "../../shared-components/constants/constants";

const JobDescription = () => {
  let { id } = useParams();

  const { data: jobData } = useJobDetails(id as string);

  return (
    <>
      <Helmet>
        <title>{`Job Id: ${id} - ${jobData?.details?.name}${
          jobData?.details?.job_location
            ? `, ${jobData?.details?.job_location}`
            : ""
        }`}</title>
        <meta
          name="title"
          content={`Job Id: ${id} - ${jobData?.details?.name}${
            jobData?.details?.job_location
              ? `, ${jobData?.details?.job_location}`
              : ""
          }`}
        />
        <meta
          name="description"
          content={`Apply for ${
            jobData?.details?.post_name
          } Job (Job Id: ${id}) ✔ in ${jobData?.details?.company_name}⭐${
            jobData?.details?.job_location
              ? ` at ${jobData?.details?.job_location},`
              : ""
          } on freshment app. ✔️ ${getFirstLetterCapital(
            jobData?.details?.job_type
          )} ✔️ ${jobData?.details?.category_name} Jobs | freshmentapp.com`}
        />
        <meta
          name="image"
          content="https://freshmentapp.com/static/media/freshmentLogo.a5200e9f.svg"
        />

        <link
          rel="canonical"
          href={`https://freshmentapp.com/job-description/${id}`}
        />
        <link
          rel="alternate"
          href={`https://freshmentapp.com/job-description/${id}`}
          hrefLang="en-in"
        />

        <meta name="author" content="freshment" />
        <meta property="business:contact_data:locality" content="Rajkot" />
        <meta property="business:contact_data:postal_code" content="360002" />
        <meta property="business:contact_data:country_name" content="India" />
        <meta
          property="business:contact_data:email"
          content="contact@freshmentapp.com"
        />
        <meta
          property="business:contact_data:website"
          content="https://freshmentapp.com"
        />
        <meta
          itemProp="name"
          content="Freshment - Find a candidate in 72 hours, Find a job in 7 days"
        />
        <meta property="profile:username" content="freshment" />
        <meta
          itemProp="description"
          content={`Apply for ${
            jobData?.details?.post_name
          } Job (Job Id: ${id}) ✔ in ${jobData?.details?.company_name}⭐${
            jobData?.details?.job_location
              ? ` at ${jobData?.details?.job_location},`
              : ""
          } on freshment app. ✔️ ${getFirstLetterCapital(
            jobData?.details?.job_type
          )} ✔️ ${jobData?.details?.category_name} Jobs | freshmentapp.com`}
        />

        <meta
          property="og:title"
          content={`Job Id: ${id} - ${jobData?.details?.name}${
            jobData?.details?.job_location
              ? `, ${jobData?.details?.job_location}`
              : ""
          }`}
        />

        <meta
          name="og:description"
          content={`Apply for ${
            jobData?.details?.post_name
          } Job (Job Id: ${id}) ✔ in ${jobData?.details?.company_name}⭐${
            jobData?.details?.job_location
              ? ` at ${jobData?.details?.job_location},`
              : ""
          } on freshment app. ✔️ ${getFirstLetterCapital(
            jobData?.details?.job_type
          )} ✔️ ${jobData?.details?.category_name} Jobs | freshmentapp.com`}
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://freshmentapp.com" />
        <meta
          property="og:determiner"
          content="Post Jobs To Get Candidates | Hire Staff Easily In 72 Hours"
        />
        <meta
          name="twitter:title"
          content={`Job Id: ${id} - ${jobData?.details?.name}${
            jobData?.details?.job_location
              ? `, ${jobData?.details?.job_location}`
              : ""
          }`}
        />

        <meta
          name="twitter:description"
          content={`Apply for ${
            jobData?.details?.post_name
          } Job (Job Id: ${id}) ✔ in ${jobData?.details?.company_name}⭐${
            jobData?.details?.job_location
              ? ` at ${jobData?.details?.job_location},`
              : ""
          } on freshment app. ✔️ ${getFirstLetterCapital(
            jobData?.details?.job_type
          )} ✔️ ${jobData?.details?.category_name} Jobs | freshmentapp.com`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="freshment" />
        <meta
          name="twitter:image"
          content="https://freshmentapp.com/static/media/about-office-1.1b443800.png"
        />
        <meta name="robots" content="index,follow" />

        <meta name="google" content="notranslate" />
        <meta http-equiv="Content-Language" content="en_US" />

        <meta
          name="keywords"
          content="fresher jobs alert, fresher jobs, infosys careers, freshment app, internships, b tech fresher jobs, it jobs in pune, it jobs in mumbai, free internship, fresher jobs at deloitte, resume writing, fresher jobs at capgemini, fresher jobs bangalore, fresher jobs bank, fresher jobs btech, fresher jobs btech cse, fresher jobs bca, fresher jobs bcom, civil engineering fresher jobs, work from home jobs for freshers, bank jobs for freshers, tcs hiring, tcs nqt"
        />
      </Helmet>
      <div className="job-description-container">
        {jobData ? <JobHeader {...jobData} /> : ""}
        <div className="px-[124px] sm:px-[40px]">
          {jobData ? <DetailedJob {...jobData} /> : ""}
        </div>
      </div>
    </>
  );
};

export default JobDescription;
