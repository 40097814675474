import { postData } from './../api-client';
import { GET_CANDIDATE_LISTING, COMPANY, ADD_CANDIDATE_ANALYSIS, SUBSCRIBE_PREMIUM_MEMBERSHIP } from '../../shared/constants/routing-constants';

export type ActionType = "downloadCV" | "sendEmail";
export type Operation = "add" | "remove";

export interface IGetCandidateListingPayload {
    access_token: string;
    company_id: string;
    job_id: string;
}

export interface ICandidateAnalysisPayload {
    operation: Operation;
    company_id: string;
    job_id: string;
    user_id: string;
    type: ActionType;
}

export interface ICompanySubscriptionPayload {
    company_id: string;
}

export function getCandidateListing(queryParam: IGetCandidateListingPayload) {
    return postData(`${COMPANY}/${GET_CANDIDATE_LISTING}`, { ...queryParam });
}

export function sendCandidateAnalysis(queryParam: ICandidateAnalysisPayload) {
    return postData(`${COMPANY}/${ADD_CANDIDATE_ANALYSIS}`, { ...queryParam });
}

export function subscribeToMembership(queryParam: ICompanySubscriptionPayload) {
    return postData(`${COMPANY}/${SUBSCRIBE_PREMIUM_MEMBERSHIP}`, { ...queryParam });
}